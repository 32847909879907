import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import JsonExcel from 'vue-json-excel' // json to excel
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)
Vue.component('downloadExcel', JsonExcel) // json to excel

// json to excel
Vue.component('downloadExcel', JsonExcel)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

router.beforeEach((to, _from, next) => {
  const authenticated = store.getters['auth/GET_AUTHSTATE']
  if (!authenticated && to.meta.requiresAuth == null) {
    next({ name: 'login' })
  } else if (authenticated && to.meta.requiresAuth === false) {
    next({ name: 'home' })
  } else {
    next()
  }
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
