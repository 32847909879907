/* eslint-disable import/no-cycle */
import request from '@/utils/request'

export function GetApplicantFunctions() {
  return request({
    url: '/api/ApplicantFunctions/GetApplicantFunctions',
    method: 'get',
  })
}

export function GetApplicantFunctionById(data) {
  return request({
    url: `/api/ApplicantFunctions/GetApplicantFunctionById/${data}`,
    method: 'get',
  })
}

export function GetApplicantFunctionByApplicantId(data) {
  return request({
    url: `/api/ApplicantFunctions/GetApplicantFunctionByApplicantId/${data}`,
    method: 'get',
  })
}

export function AddApplicantFunction(data) {
  return request({
    url: '/api/ApplicantFunctions/AddApplicantFunction',
    method: 'post',
    data,
  })
}

export function UpdateApplicantFunction(data) {
  return request({
    url: '/api/ApplicantFunctions/UpdateApplicantFunction',
    method: 'put',
    data,
  })
}

export function DeleteApplicantFunction(data) {
  return request({
    url: `/api/ApplicantFunctions/DeleteApplicantFunction?applicantFunctionId=${data}`,
    method: 'delete',
  })
}

/*
        [HttpGet("GetApplicantFunctions")]
        [HttpGet("GetApplicantFunctionById/{id}")]
        [HttpGet("GetApplicantFunctionByApplicantId/{applicantId}")]
        [HttpPost("AddApplicantFunction")]
        [HttpPut("UpdateApplicantFunction")]
        [HttpDelete("DeleteApplicantFunction")]
*/
