/* eslint-disable import/no-cycle */
import request from '@/utils/request'

export function GetApplicantExperiences() {
  return request({
    url: '/api/ApplicantExperiences/GetApplicantExperiences',
    method: 'get',
  })
}

export function GetApplicantExperienceById(data) {
  return request({
    url: `/api/ApplicantExperiences/GetApplicantExperienceById/${data}`,
    method: 'get',
  })
}

export function GetApplicantExperienceByApplicantId(data) {
  return request({
    url: `/api/ApplicantExperiences/GetApplicantExperienceByApplicantId/${data}`,
    method: 'get',
  })
}

export function AddApplicantExperience(data) {
  return request({
    url: '/api/ApplicantExperiences/AddApplicantExperience',
    method: 'post',
    data,
  })
}

export function UpdateApplicantExperience(data) {
  return request({
    url: '/api/ApplicantExperiences/UpdateApplicantExperience',
    method: 'put',
    data,
  })
}

export function DeleteApplicantExperience(data) {
  return request({
    url: `/api/ApplicantExperiences/DeleteApplicantExperience?applicantExperiencesId=${data}`,
    method: 'delete',
  })
}

/*
        [HttpGet("GetApplicantExperiences")]
        [HttpGet("GetApplicantExperienceById/{id}")]
        [HttpGet("GetApplicantExperienceByApplicantId/{applicantId}")]
        [HttpPost("AddApplicantExperience")]
        [HttpPut("UpdateApplicantExperience")]
        [HttpDelete("DeleteApplicantExperience")]
*/
