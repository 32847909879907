/* eslint-disable import/no-cycle */
import request from '@/utils/request'

export function GetStaffRequestCertifications() {
  return request({
    url: '/api/StaffRequestCertifications/GetStaffRequestCertifications',
    method: 'GET',
  })
}

export function GetStaffRequestCertificationById(data) {
  return request({
    url: `/api/StaffRequestCertifications/GetStaffRequestCertificationtById/${data}`,
    method: 'GET',
  })
}

export function GetStaffRequestCeritificationByRequestId(data) {
  return request({
    url: `/api/StaffRequestCertifications/GetStaffRequestCertificationByRequestId/${data}`,
    method: 'GET',
  })
}

export function AddStaffRequestCertification(data) {
  return request({
    url: '/api/StaffRequestCertifications/AddStaffRequestCertification',
    method: 'POST',
    data,
  })
}

export function UpdateStaffRequestCertification(data) {
  return request({
    url: '/api/StaffRequestCertifications/UpdateStaffRequestCertification',
    method: 'PUT',
    data,
  })
}

export function DeleteStaffRequestCertification(data) {
  return request({
    url: `/api/StaffRequestCertifications/DeleteStaffRequestCertification/${data}`,
    method: 'DELETE',
  })
}
