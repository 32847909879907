/* eslint-disable import/no-cycle */
import request from '@/utils/request'

export function GetFirmsAll() {
  return request({
    url: '/api/FirmInfo/GetFirmsAll',
    method: 'get',
  })
}
// http://localhost:5000/api/FirmInfo/GetIncompletedFirmsAll
export function GetIncompletedFirmsAll() {
  return request({
    url: '/api/FirmInfo/GetIncompletedFirmsAll',
    method: 'get',
  })
}

export function GetFirm(data) {
  return request({
    url: `/api/FirmInfo/GetFirm/${data}`,
    method: 'get',
  })
}
// GetFirmByUserId/45

export function GetFirmByUserId(data) {
  return request({
    url: `/api/FirmInfo/GetFirmByUserId/${data}`,
    method: 'get',
  })
}

export function AddFirm(data) {
  return request({
    url: '/api/FirmInfo/AddFirm',
    method: 'post',
    data,
  })
}

export function UpdateFirm(data) {
  return request({
    url: '/api/FirmInfo/UpdateFirm',
    method: 'put',
    data,
  })
}
export function DeleteFirm(data) {
  return request({
    url: `/api/FirmInfo/DeleteFirm?firmInfoId=${data}`,
    method: 'delete',
  })
}

export function CheckValidation(data) {
  return request({
    url: `/api/FirmInfo/CheckValidation?firmId=${data}`,
    method: 'put',
  })
}
