/* eslint-disable import/no-cycle */
import * as sehir from '@/api/tanimlar/sehir'

export default {
  namespaced: true,
  state: {
    city: { id: 0, name: '', plateCode: 0 },
    list: [],
    page: 1,
  },
  getters: {
    GET_CITYLIST: state => state.list,
    GET_PAGE: state => state.page,
    GET_CITY: state => state.city,
  },
  mutations: {
    SET_CITYLIST: (state, list) => {
      state.list = list
    },
    SET_CITY: (state, city) => {
      state.city = city
    },
    PUSH_LIST: (state, data) => {
      state.list.push(state.list, data)
    },
    INCREASE_PAGE: state => {
      state.page += 1
    },
    SET_PAGE: (state, page) => {
      state.page = page
    },
  },
  actions: {
    action_get_cities({ commit }, data) {
      return new Promise((resolve, reject) => {
        sehir.getCityByCountryId(data).then(res => {
          commit('SET_CITYLIST', res.data.list)
          resolve(true)
        }).catch(error => {
          reject(error)
        })
      })
    },
    action_get_city({ commit }, data) {
      return new Promise((resolve, reject) => {
        sehir.getCityByCountryId(data).then(res => {
          if (res.data.entity === null) {
            reject(res.exceptionMessage)
          }
          const city = { id: res.data.entity.cityId, name: res.data.entity.cityName, plateCode: res.data.entity.plateCode }
          commit('City', city)

          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },
  },
}
