/* eslint-disable import/no-cycle */
import request from '@/utils/request'

export function GetStaffRequests() {
  return request({
    url: '/api/StaffRequests/GetStaffRequests',
    method: 'get',
  })
}

export function GetIncompletedStaffRequests() {
  return request({
    url: '/api/StaffRequests/GetIncompletedStaffRequests',
    method: 'get',
  })
}

export function GetStaffRequestById(data) {
  return request({
    url: `/api/StaffRequests/GetStaffRequestById/${data}`,
    method: 'get',
  })
}

export function GetStaffRequestByFirmId(data) {
  return request({
    url: `/api/StaffRequests/GetStaffRequestByFirmId/${data}`,
    method: 'get',
  })
}

export function AddStaffRequest(data) {
  return request({
    url: '/api/StaffRequests/AddStaffRequest',
    method: 'POST',
    data,
  })
}

export function UpdateStaffRequest(data) {
  return request({
    url: '/api/StaffRequests/UpdateStaffRequest',
    method: 'put',
    data,
  })
}

export function DeleteStaffRequest(data) {
  return request({
    url: `/api/StaffRequests/DeleteStaffRequest?frStaffRequestId=${data}`,
    method: 'delete',
  })
}

export function GetSummary(data) {
  return request({
    url: `/api/StaffRequests/StaffRequestSummary/${data}`,
    method: 'get',
  })
}

export function GetSummaryByFirmId(data, firmId) {
  return request({
    url: `/api/StaffRequests/StaffRequestSummaryByFirmId/${data}/${firmId}`,
    method: 'get',
  })
}

export function StaffRequestSummaryIncompleted() {
  return request({
    url: '/api/StaffRequests/StaffRequestSummaryIncompleted',
    method: 'get',
  })
}

export function CheckValidation(data) {
  return request({
    url: `/api/StaffRequests/CheckValidation?staffRequestId=${data}`,
    method: 'put',
  })
}
