/* eslint-disable import/no-cycle */
import request from '@/utils/request'

// eslint-disable-next-line import/prefer-default-export
export function getCountries() {
  return request({
    url: '/api/Country/GetCountriesAll/',
    method: 'get',
  })
}

export function getCountryById(data) {
  return request({
    url: `/api/Country/GetCountryById/${data}`,
    method: 'get',
  })
}
export function addCountry(data) {
  return request({
    url: '/api/Country/AddCountry',
    method: 'post',
    data,
  })
}

export function updateCountry(data) {
  return request({
    url: '/api/Country/UpdateCountry',
    method: 'put',
    data,
  })
}

export function deleteCountry(data) {
  return request({
    url: `/api/Country/DeleteCountry?countryId=${data}`,
    method: 'delete',
  })
}
