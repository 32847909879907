/* eslint-disable import/no-cycle */
import request from '@/utils/request'

export function GetApplicantInfo() {
  return request({
    url: '/api/Applicants/GetApplicants',
    method: 'get',
  })
}

export function GetApplicantById(data) {
  return request({
    url: `/api/Applicants/GetApplicantById/${data}`,
    method: 'get',
  })
}
export function GetApplicantByUserId(data) {
  return request({
    url: `/api/Applicants/GetApplicantByUserId/${data}`,
    method: 'get',
  })
}

export function GetIncompletedApplicants() {
  return request({
    url: '/api/Applicants/GetIncompletedApplicants',
    //    /api/Applicants/GetIncompletedApplicants
    method: 'get',
  })
}
export function AddApplicant(data) {
  return request({
    url: '/api/Applicants/AddApplicant',
    method: 'post',
    data,
  })
}

export function UpdateApplicant(data) {
  return request({
    url: '/api/Applicants/UpdateApplicant',
    method: 'put',
    data,
  })
}

export function DeleteApplicant(data) {
  return request({
    url: `/api/Applicants/DeleteApplicant?applicantId=${data}`,
    method: 'delete',
    data,
  })
}

export function GetApplicantEvaluationCvPointsByApplicantId(data) {
  return request({
    url: `/api/Applicants/GetApplicantEvaluationCvPointsByApplicantId?applicantId=${data}`,
    method: 'get',
  })
}

export function GetApplicantEvaluationCvPointsSummaryByApplicantId(data) {
  return request({
    url: `/api/Applicants/GetApplicantEvaluationCvPointsSummaryByApplicantId?applicantId=${data}`,
    method: 'get',
  })
}

export function CheckValidation(data) {
  return request({
    url: `/api/Applicants/CheckValidation?applicantId=${data}`,
    method: 'put',
  })
}
