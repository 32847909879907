import axios from 'axios'
import { getToken } from '@/utils/auth'
// eslint-disable-next-line import/no-cycle
import store from '../store'

const settings = require('../settings.json')
// create an axios instance
const service = axios.create({
  baseURL: settings.apiURL,
  timeout: 90000,
})

service.interceptors.request.use(
  config => {
    // do something before request is sent
    const token = getToken()
    if (token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = token
    }
    return config
  },
  error => Promise.reject(error),
)

service.interceptors.response.use(
  response => (response.status === 200 ? response.data : Promise.reject(new Error('Hata,İşlemlerinizi Tekrar Kontrol Ediniz.'))),
  error => {
    const errorMsg = new Error(`Status: ${error.response.status} Message:${error.response.data}`)
    switch (error.response.status) {
      case 401:
        store.dispatch('auth/action_logout').then(() => {
          window.location.href = '/'
        })
        return Promise.reject(errorMsg)
      default: return Promise.reject(errorMsg)
    }
  },
)

export default service
