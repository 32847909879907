/* eslint-disable import/no-cycle */
import request from '@/utils/request'

export function GetFirmContactInfosAll() {
  return request({
    url: '​/api​/FirmContactInfo​/GetContactInfoAll',
    method: 'get',
  })
}

export function GetFirmContactInfoByFirmId(data) {
  return request({
    url: `/api/FirmContactInfo/GetContactInfoByFirmId/${data}`,
    method: 'get',
  })
}

export function AddFirmContactInfo(data) {
  return request({
    url: '/api/FirmContactInfo/AddFirmContactInfo',
    method: 'post',
    data,
  })
}

export function UpdateFirmContactInfo(data) {
  return request({
    url: '/api/FirmContactInfo/UpdateFirmContactInfo',
    method: 'put',
    data,
  })
}

export function DeleteFirmContactInfo(data) {
  return request({
    url: '/api/FirmContactInfo/DeleteFirmContactInfo',
    method: 'delete',
    data,
  })
}
