import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'hash',
  pageTitle: 'İstihdam Yönetim Sistemi',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { // HOME
      path: '/',
      name: 'home',
      component: () => import('@/views/Home2.vue'),
      meta: {
        pageTitle: 'Ana Sayfa',
        breadcrumb: [
          {
            text: 'Ana Sayfa',
            active: true,
          },
        ],
      },
    },
    { // KULLANICI
      path: '/account-settings',
      name: 'accountSettings',
      component: () => import('@/views/useraccounts/AccountSetting.vue'),
      meta: {
        pageTitle: 'Hesap Ayarları',
        breadcrumb: [
          {
            text: 'Hesap Ayarları',
            active: true,
          },
        ],
      },
    },
    { // TANIMLAR
      path: '/tanimlar',
      component: () => import('@/views/tanimlar/Tanimlar.vue'),
      name: 'tanimlar',
      children: [
        {
          path: 'ulke-tanimlari',
          name: 'ulke-tanimlari',
          component: () => import('@/views/tanimlar/Ulke.vue'),
          meta: {
            pageTitle: 'Ülke Tanımları',
            breadcrumb: [
              {
                text: 'Tanımlar',
                active: true,
              },
              {
                text: 'Ülke Tanımları',
                active: true,
              },
            ],
          },
        },
        {
          path: 'sehir-tanimlari',
          name: 'sehir-tanimlari',
          component: () => import('@/views/tanimlar/Sehir.vue'),
          meta: {
            pageTitle: 'Şehir Tanımları',
            breadcrumb: [
              {
                text: 'Tanımlar',
                active: true,
              },
              {
                text: 'Şehir Tanımları',
                active: true,
              },
            ],
          },
        },
        {
          path: 'town-tanimlari',
          name: 'town-tanimlari',
          component: () => import('@/views/tanimlar/Town.vue'),
          meta: {
            pageTitle: 'İlçe Tanımları',
            breadcrumb: [
              {
                text: 'Tanımlar',
                active: true,
              },
              {
                text: 'İlçe Tanımları',
                active: true,
              },
            ],
          },
        },
        {
          path: 'universite-tanimlari',
          name: 'universite-tanimlari',
          component: () => import('@/views/tanimlar/University.vue'),
          meta: {
            pageTitle: 'Üniversite Tanımları',
            breadcrumb: [
              {
                text: 'Tanımlar',
                active: true,
              },
              {
                text: 'Üniversite Tanımları',
                active: true,
              },
            ],
          },
        },
        {
          path: 'sektor-tanimlari',
          name: 'sektor-tanimlari',
          component: () => import('@/views/tanimlar/sectors.vue'),
          meta: {
            pageTitle: 'Sektör Tanımları',
            breadcrumb: [
              {
                text: 'Tanımlar',
                active: true,
              },
              {
                text: 'Sektör Tanımları',
                active: true,
              },
            ],
          },
        },
        {
          path: 'firmatip-tanimlari',
          name: 'firmatip-tanimlari',
          component: () => import('@/views/tanimlar/firmatip.vue'),
          meta: {
            pageTitle: 'Firma Tip Tanımları',
            breadcrumb: [
              {
                text: 'Tanımlar',
                active: true,
              },
              {
                text: 'Firma Tip Tanımları',
                active: true,
              },
            ],
          },
        },
        {
          path: 'pozisyon-tanimlari',
          name: 'pozisyon-tanimlari',
          component: () => import('@/views/tanimlar/pozisyon.vue'),
          meta: {
            pageTitle: 'Pozisyon Tanımları',
            breadcrumb: [
              {
                text: 'Tanımlar',
                active: true,
              },
              {
                text: 'Pozisyon Tanımları',
                active: true,
              },
            ],
          },
        },
        {
          path: 'lisans-bolumleri',
          name: 'lisans-bolumleri',
          component: () => import('@/views/tanimlar/BachelorDegree.vue'),
          meta: {
            pageTitle: 'Lisans Bölümleri',
            breadcrumb: [
              {
                text: 'Tanımlar',
                active: true,
              },
              {
                text: 'Lisans Bölümleri',
                active: true,
              },
            ],
          },
        },
        {
          path: 'on-lisans-bolumleri',
          name: 'on-lisans-bolumleri',
          component: () => import('@/views/tanimlar/AssociateDegree.vue'),
          meta: {
            pageTitle: 'Ön Lisans Bölümleri',
            breadcrumb: [
              {
                text: 'Tanımlar',
                active: true,
              },
              {
                text: 'Ön Lisans Bölümleri',
                active: true,
              },
            ],
          },
        },
        {
          path: 'meslek-lisesi-bolumleri',
          name: 'meslek-lisesi-bolumleri',
          component: () => import('@/views/tanimlar/VocationalHighSchoolDepartment.vue'),
          meta: {
            pageTitle: 'Meslek Lisesi Bölümleri',
            breadcrumb: [
              {
                text: 'Tanımlar',
                active: true,
              },
              {
                text: 'Meslek Lisesi Bölümleri',
                active: true,
              },
            ],
          },
        },
        {
          path: 'yabanci-diller',
          name: 'yabanci-diller',
          component: () => import('@/views/tanimlar/Language.vue'),
          meta: {
            pageTitle: 'Yabancı Diller',
            breadcrumb: [
              {
                text: 'Tanımlar',
                active: true,
              },
              {
                text: 'Yabancı Diller',
                active: true,
              },
            ],
          },
        },
        {
          path: 'yabanci-dil-sinav',
          name: 'yabanci-dil-sinav',
          component: () => import('@/views/tanimlar/LanguageLevels.vue'),
          meta: {
            pageTitle: 'Yabancı Dil Sınavları',
            breadcrumb: [
              {
                text: 'Tanımlar',
                active: true,
              },
              {
                text: 'Yabancı Dil Sınavları',
                active: true,
              },
            ],
          },
        },
        {
          path: 'anlasmazlik-nedenleri',
          name: 'anlasmazlik-nedenleri',
          component: () => import('@/views/tanimlar/DisagreementReason.vue'),
          meta: {
            pageTitle: 'Anlaşmazlık Nedenleri',
            breadcrumb: [
              {
                text: 'Tanımlar',
                active: true,
              },
              {
                text: 'Anlaşmazlık Nedenleri',
                active: true,
              },
            ],
          },
        },
        {
          path: 'personel-egitimi',
          name: 'personel-egitimi',
          component: () => import('@/views/tanimlar/StaffEducation.vue'),
          meta: {
            pageTitle: 'Personel Eğitimi',
            breadcrumb: [
              {
                text: 'Tanımlar',
                active: true,
              },
              {
                text: 'Personel Eğitimi',
                active: true,
              },
            ],
          },
        },
        {
          path: 'personel-bulma-yontemi',
          name: 'personel-bulma-yontemi',
          component: () => import('@/views/tanimlar/StaffingMethod.vue'),
          meta: {
            pageTitle: 'Personel Bulma Yöntemleri',
            breadcrumb: [
              {
                text: 'Tanımlar',
                active: true,
              },
              {
                text: 'Personel Bulma Yöntemleri',
                active: true,
              },
            ],
          },
        },
        {
          path: 'yuksek-lisans-bolumleri',
          name: 'yuksek-lisans-bolumleri',
          component: () => import('@/views/tanimlar/Postgraduate.vue'),
          meta: {
            pageTitle: 'Yüksek Lisans Bölümleri',
            breadcrumb: [
              {
                text: 'Tanımlar',
                active: true,
              },
              {
                text: 'Yüksek Lisans Bölümleri',
                active: true,
              },
            ],
          },
        },
        {
          path: 'sertifika-tanimlari',
          name: 'sertifika-tanimlari',
          component: () => import('@/views/tanimlar/Certificate.vue'),
          meta: {
            pageTitle: 'Sertifikalar',
            breadcrumb: [
              {
                text: 'Tanımlar',
                active: true,
              },
              {
                text: 'Sertifikalar',
                active: true,
              },
            ],
          },
        },
        {
          path: 'surucu-belgesi-tipleri',
          name: 'surucu-belgesi-tipleri',
          component: () => import('@/views/tanimlar/DriverLicenceType.vue'),
          meta: {
            pageTitle: 'Sürücü Belgesi Tipleri',
            breadcrumb: [
              {
                text: 'Tanımlar',
                active: true,
              },
              {
                text: 'Sürücü Belgesi Tipleri',
                active: true,
              },
            ],
          },
        },
        {
          path: 'calismaya-engel-hastaliklar',
          name: 'calismaya-engel-hastaliklar',
          component: () => import('@/views/tanimlar/ObstacleDisease.vue'),
          meta: {
            pageTitle: 'Çalışmaya Engel Hastalıklar',
            breadcrumb: [
              {
                text: 'Tanımlar',
                active: true,
              },
              {
                text: 'Çalışmaya Engel Hastalıklar',
                active: true,
              },
            ],
          },
        },
        {
          path: 'sosyal-imkanlar',
          name: 'sosyal-imkanlar',
          component: () => import('@/views/tanimlar/SocialOpportunity.vue'),
          meta: {
            pageTitle: 'Sosyal İmkanlar',
            breadcrumb: [
              {
                text: 'Tanımlar',
                active: true,
              },
              {
                text: 'Sosyal İmkanlar',
                active: true,
              },
            ],
          },
        },
        {
          path: 'hobi-factor',
          name: 'hobi-factor',
          component: () => import('@/views/tanimlar/hobiFactor.vue'),
          meta: {
            pageTitle: 'Hobi Faktörleri',
            breadcrumb: [
              {
                text: 'Tanımlar',
                active: true,
              },
              {
                text: 'Hobi Faktörleri',
                active: true,
              },
            ],
          },
        },
        {
          path: 'mahalle-tanimlari',
          name: 'mahalle-tanimlari',
          component: () => import('@/views/tanimlar/Neighborhood.vue'),
          meta: {
            pageTitle: 'Mahalle Tanımları',
            breadcrumb: [
              {
                text: 'Tanımlar',
                active: true,
              },
              {
                text: 'Mahalle Tanımları',
                active: true,
              },
            ],
          },
        },
        {
          path: 'bilgisayar-yetenek-tanimlari',
          name: 'bilgisayar-yetenek-tanimlari',
          component: () => import('@/views/tanimlar/ComputerKnowledge.vue'),
          meta: {
            pageTitle: 'Bilgisayar Yetenek Tanımları',
            breadcrumb: [
              {
                text: 'Tanımlar',
                active: true,
              },
              {
                text: 'Bilgisayar Yetenek Tanımları',
                active: true,
              },
            ],
          },
        },
      ],
    },
    { // LOGIN
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
        requiresAuth: false,
      },
    },
    { // verification
      path: '/emailverification',
      name: 'emailverification',
      component: () => import('@/views/emailverification.vue'),
      meta: {
        layout: 'full',
        requiresAuth: false,
      },
    },
    { // USER SIGN UP
      path: '/signup',
      name: 'signUp',
      component: () => import('@/views/usersignup/SignUpLayout.vue'),
      meta: {
        layout: 'full',
      },
      children: [
        {
          path: 'applicant',
          name: 'signUpApplicant',
          component: () => import('@/views/usersignup/SignUpApplicant.vue'),
          meta: {
            layout: 'full',
            requiresAuth: true,
          },
        },
        {
          path: 'employer',
          name: 'signUpEmployer',
          component: () => import('@/views/usersignup/SignUpEmployer.vue'),
          meta: {
            layout: 'full',
            requiresAuth: true,
          },
        },
        {
          path: 'account',
          name: 'signUpAccount',
          component: () => import('@/views/usersignup/SignUpAccount.vue'),
          meta: {
            layout: 'full',
            requiresAuth: false,
          },
        },
      ],
    },
    { // İŞLEMLER
      path: '/islemler',
      component: () => import('@/views/islemler/islemler.vue'),
      name: 'islemler',
      children: [
        {
          path: 'firma-kayitlari',
          name: 'firma-kayitlari',
          component: () => import('@/views/islemler/FirmaEnvanter.vue'),
          meta: {
            pageTitle: 'İşveren Listesi',
            breadcrumb: [
              {
                text: 'İşveren Ekle',
                active: false,
                to: 'firma-kart',
              },
              {
                text: 'İşveren Listesi',
                active: true,
              },
            ],
          },
        },
        {
          path: 'firma-talepleri',
          name: 'firma-talepleri',
          component: () => import('@/views/islemler/firmalarTalep.vue'),
          meta: {
            pageTitle: 'İşveren Talepleri',
            breadcrumb: [
              {
                text: 'İşveren Listesi',
                active: false,
                to: 'firma-kayitlari',
              },
              {
                text: 'İşveren Talepleri',
                active: true,
              },
            ],
          },
        }, // talep-liste
        {
          path: 'talep-liste',
          name: 'talep-liste',
          component: () => import('@/views/islemler/taleplist.vue'),
          meta: {
            pageTitle: 'Talepler Listesi',
            breadcrumb: [
              {
                text: 'Talep Oluştur',
                active: false,
                to: 'firma-talepleri',
              },
              {
                text: 'Talepler Listesi',
                active: true,
              },
            ],
          },
        },
        {
          path: 'firm-request',
          name: 'firm-request',
          component: () => import('@/views/islemler/talepListesi.vue'),
          meta: {
            pageTitle: 'Eşleştirme yapılmamış talepler',
            breadcrumb: [
              {
                text: 'Talep Listesi',
                active: false,
                to: 'talep-liste',
              },
              {
                text: 'Eşleştirilecek talepler',
                active: true,
              },
            ],
          },
        },
        {
          path: 'talep-yonlendir',
          name: 'talep-yonlendir',
          component: () => import('@/views/islemler/talepyonlendir.vue'),
          meta: {
            pageTitle: 'Talep Yönlendirme',
            breadcrumb: [
              {
                text: 'Eşleştirilecek Talepler',
                active: false,
                to: { name: 'firm-request' },
              },
              {
                text: 'Talep Yönlendirme',
                active: true,
              },
            ],
          },
        },
        {
          path: 'talep-yonlendirme',
          name: 'talep-yonlendirme',
          component: () => import('@/views/islemler/talepyonlendirme.vue'),
          meta: {
            pageTitle: 'Talep Yönlendirme',
            breadcrumb: [
              {
                text: 'Yönlendirilecek Talepler',
                active: false,
                to: { name: 'talep-yonlendir' },
              },
              {
                text: 'Talep Yönlendirme',
                active: true,
              },
            ],
          },
        },
        {
          path: 'aday-kayit',
          name: 'aday-Kayit',
          component: () => import('@/views/islemler/aday/adayyeni.vue'),
          meta: {
            pageTitle: 'Aday Kayıt',
            breadcrumb: [
              {
                text: 'İşlemler',
                active: true,
              },
              {
                text: 'Aday Kayıt',
                active: true,
              },
            ],
          },
        },
        {
          path: 'aday-listesi',
          name: 'aday-listesi',
          component: () => import('@/views/islemler/aday/adayListesi.vue'),
          meta: {
            pageTitle: 'Aday Listesi',
            breadcrumb: [
              {
                text: 'İşlemler',
                active: true,
              },
              {
                text: 'Aday Listesi',
                active: true,
              },
            ],
          },
        },
        {
          path: 'aday-tamamla',
          name: 'aday-tamamla',
          component: () => import('@/views/islemler/aday/adayTamamla.vue'),
          meta: {
            pageTitle: 'Aday Kayıt Tamamlama',
            breadcrumb: [
              {
                text: 'Aday Listesi',
                active: false,
                to: 'aday-listesi',
              },
              {
                text: 'Aday Kayıt Tamamlama',
                active: true,
              },
            ],
          },
        },
        {
          path: 'aday-ozgecmis',
          name: 'aday-ozgecmis',
          component: () => import('@/views/islemler/aday/ozgecmis.vue'),
          meta: {
            pageTitle: 'Aday Özgeçmiş',
            breadcrumb: [
              {
                text: 'Aday Listesi',
                active: false,
                to: { name: 'aday-listesi' },
              },
              {
                text: 'Aday Özgeçmiş',
                active: true,
              },
            ],
          },
        },
        {
          path: 'isveren-tamamla',
          name: 'isveren-tamamla',
          component: () => import('@/views/islemler/firma/firmaTamamla.vue'),
          meta: {
            pageTitle: 'İşveren Kayıt Tamamlama',
            breadcrumb: [
              {
                text: 'İşveren Listesi',
                active: false,
                to: 'firma-kayitlari',
              },
              {
                text: 'İşveren Kayıt Tamamlama',
                active: true,
              },
            ],
          },
        },
        {
          path: 'talep-tamamlama',
          name: 'talep-tamamlama',
          component: () => import('@/views/islemler/talep/talepTamamla.vue'),
          meta: {
            pageTitle: 'İşveren Talep Tamamlama',
            breadcrumb: [
              {
                text: 'Talep Listesi',
                active: false,
                to: { name: 'talep-liste' },
              },
              {
                text: 'İşveren Talep Tamamlama',
                active: true,
              },
            ],
          },
        },
      ],
    },
    { // FIRMA
      path: '/firma',
      name: 'firma',
      component: () => import('@/views/islemler/firma/firma.vue'),
      children: [
        { // firma tümleşik formu
          path: 'firma-kart',
          name: 'firma-kart',
          component: () => import('@/views/islemler/firma/FirmaKart.vue'),
          meta: {
            pageTitle: 'İşveren Bilgileri',
            breadcrumb: [
              {
                text: 'İşlemler',
                active: true,
              },
              {
                text: 'İşveren Envanteri',
                active: false,
                to: { name: 'firma-kayitlari' },
              },
              {
                text: 'İşveren Bilgileri',
                active: true,
              },
            ],
          },
        },
        { // firma ozeli
          path: 'firma-bilgilerim',
          name: 'firma-bilgilerim',
          component: () => import('@/views/islemler/firma/firmaBilgilerim.vue'),
          meta: {
            pageTitle: 'İşveren Bilgilerim',
            breadcrumb: [
              {
                text: 'İşlemler',
                active: true,
              },
              {
                text: 'İşveren Envanteri',
                active: false,
                to: { name: 'firma-bilgilerim' },
              },
              {
                text: 'İşveren Bilgilerim',
                active: true,
              },
            ],
          },
        },
        { // firma ozeli
          path: 'firma-olumsuz-adaylar',
          name: 'firma-olumsuz-adaylar',
          component: () => import('@/views/raporlar/Firma/olumsuzaday.vue'),
          meta: {
            pageTitle: 'Olumsuz Aday Listesi',
            breadcrumb: [
              {
                text: 'Raporlar',
                active: true,
              },
              {
                text: 'Olumsuz Adaylar',
                active: false,
                to: { name: 'firma-olumsuz-adaylar' },
              },
              {
                text: 'Olumsuz Adayların Listesi',
                active: true,
              },
            ],
          },
        },
        { // firma ozeli
          path: 'firma-ise-aldiklarim',
          name: 'firma-ise-aldiklarim',
          component: () => import('@/views/raporlar/Firma/isealdiklarim.vue'),
          meta: {
            pageTitle: 'İşe Aldığım Aday Listesi',
            breadcrumb: [
              {
                text: 'Raporlar',
                active: true,
              },
              {
                text: 'İşe Aldıklarım',
                active: false,
                to: { name: 'firma-ise-aldiklarim' },
              },
              {
                text: 'İşe Aldıklarımın Listesi',
                active: true,
              },
            ],
          },
        },
        { // firma ozeli
          path: 'firma-taleplerim',
          name: 'firma-taleplerim',
          component: () => import('@/views/islemler/myrequests.vue'),
          meta: {
            pageTitle: 'Taleplerim',
            breadcrumb: [
              {
                text: 'İşlemler',
                active: true,
              },
              {
                text: 'İşveren Talepleri',
                active: false,
                to: { name: 'firma-taleplerim' },
              },
              {
                text: 'İş İlanlarım',
                active: true,
              },
            ],
          },
        },
        { // firma ozeli
          path: 'sifre-degistir',
          name: 'sifre-degistir',
          component: () => import('@/views/islemler/firma/sifredegistir.vue'),
          meta: {
            pageTitle: 'Şifre Değiştir',
            breadcrumb: [
              {
                text: 'İşlemler',
                active: true,
              },
              {
                text: 'Şifre Değiştirme',
                active: false,
                to: { name: 'sifre-degistir' },
              },
              {
                text: 'Firma Şifre Değiştir',
                active: true,
              },
            ],
          },
        },
        { // firma ozeli
          path: 'talep-eslesme-ozet',
          name: 'talep-eslesme-ozet',
          component: () => import('@/views/islemler/firma/components/TalepEslesmeOzet.vue'),
          meta: {
            pageTitle: 'Talep Eşleşme Özeti',
            breadcrumb: [
              // İşlemler İşveren Talepleri İş İlanlarım
              {
                text: 'İşlemler',
                active: true,
              },
              {
                text: 'İş İlanlarım',
                active: false,
                to: { name: 'firma-taleplerim' },
              },
              {
                text: 'Talep Eşleşme Özeti',
                active: true,
              },
            ],
          },
        },
        { // firma ozeli
          path: 'talep-uygun-yonlendirme',
          name: 'talep-uygun-yonlendirme',
          component: () => import('@/views/islemler/firma/components/TalepUygunYonlendirme.vue'),
          meta: {
            pageTitle: 'Talebe Uygun Yönlendirmeler',
            breadcrumb: [
              // İşlemler İşveren Talepleri İş İlanlarım
              {
                text: 'İşlemler',
                active: true,
              },
              {
                text: 'İş İlanlarım',
                active: false,
                to: { name: 'firma-taleplerim' },
              },
              {
                text: 'Talebe Uygun Yönlendirmeler',
                active: true,
              },
            ],
          },
        },
      ],
    },
    { // FIRMA TALEP
      path: '/islemler',
      name: 'firma-talep',
      component: () => import('@/views/islemler/talep/talepparent.vue'),
      children: [
        {
          path: 'yeni-talep',
          name: 'yeni-talep',
          component: () => import('@/views/islemler/talep/yenitalep.vue'),
          meta: {
            pageTitle: 'Yeni talep kaydı',
            breadcrumb: [
              {
                text: 'İşlemler',
                active: true,
              },
              {
                text: 'İşveren Yeni Talep',
                active: false,
                to: { name: 'firma-talepleri' },
              },
              {
                text: 'Yeni talep',
                active: true,
              },
            ],
          },
        },
      ],
    },
    // { // FIRMA TALEP
    //   path: '/islemler',
    //   name: 'firma-talep',
    //   component: () => import('@/views/islemler/talep/talepparent.vue'),
    //   children: [
    //     {
    //       path: 'firm-request',
    //       name: 'firm-request',
    //       component: () => import('@/views/islemler/talepListesi.vue'),
    //       meta: {
    //         pageTitle: 'Eşleştirme yapılmamış talepler',
    //         breadcrumb: [
    //           {
    //             text: 'İşlemler',
    //             active: true,
    //           },
    //           {
    //             text: 'Eşleştirilecek talepler',
    //             active: true,
    //             // to: { name: 'firm-request' },
    //           },
    //         ],
    //       },
    //     },
    //   ],
    // },
    { // ADAY
      path: '/islemler',
      name: 'aday',
      component: () => import('@/views/islemler/aday/adayparent.vue'),
      children: [
        {
          path: 'aday-kayit',
          name: 'aday-kayit',
          component: () => import('@/views/islemler/aday/adayyeni.vue'),
          meta: {
            pageTitle: 'Aday Yeni Kayıt',
            breadcrumb: [
              {
                text: 'İşlemler',
                active: true,
              },
              {
                text: 'Aday Kayıt',
                active: false,
                to: { name: 'aday-kayit' },
              },
              {
                text: 'Yeni kayıt',
                active: true,
              },
            ],
          },
        },
        // {
        //   path: 'aday-listesi',
        //   name: 'aday-listesi',
        //   component: () => import('@/views/islemler/aday/adayListesi.vue'),
        //   meta: {
        //     pageTitle: 'Aday Listesi',
        //     breadcrumb: [
        //       {
        //         text: 'İşlemler',
        //         active: true,
        //       },
        //       {
        //         text: 'Aday Listesi',
        //         active: true,
        //       },
        //     ],
        //   },
        // },
        {
          path: 'aday-bilgilerim',
          name: 'aday-bilgilerim',
          component: () => import('@/views/islemler/aday/adaybilgilerim.vue'),
          props: { },
          meta: {
            pageTitle: 'Aday İşlemlerim',
            breadcrumb: [
              {
                text: 'Aday İşlemlerim',
                active: true,
              },
              {
                text: 'Kayıt Bilgilerim',
                active: true,
              },
            ],
          },
        },
      ],
    },
    { // RAPORLAR
      path: '/raporlar',
      name: 'raporlar',
      component: () => import('@/views/raporlar/raporlar.vue'),
      children: [
        {
          path: 'rapor-aday',
          name: 'rapor-aday',
          component: () => import('@/views/raporlar/aday/aktifadayliste.vue'),
          meta: {
            pageTitle: 'Güncel Aday Listesi',
            breadcrumb: [
              {
                text: 'Raporlar',
                active: true,
              },
              {
                text: 'Aday Listesi',
                active: false,
                to: { name: 'rapor-aday' },
              },
            ],
          },
        },
        {
          path: 'aday-istihdam',
          name: 'aday-istihdam',
          component: () => import('@/views/raporlar/aday/istihdamEdilmisListe.vue'),
          meta: {
            pageTitle: 'İstihdam Edilmiş Aday Listesi',
            breadcrumb: [
              {
                text: 'Raporlar',
                active: true,
              },
              {
                text: 'İstihdam Edilmiş Adaylar',
                active: true,
                to: { name: 'aday-istihdam' },
              },
            ],
          },
        },
        {
          path: 'aday-bekler',
          name: 'aday-bekler',
          component: () => import('@/views/raporlar/aday/bekleyenAdaylar.vue'),
          meta: {
            pageTitle: 'Bekleyen Aday Listesi',
            breadcrumb: [
              {
                text: 'Raporlar',
                active: true,
              },
              {
                text: 'Bekleyen Adaylar',
                active: true,
                to: { name: 'aday-bekler' },
              },
            ],
          },
        },
        {
          path: 'aday-olumsuz',
          name: 'aday-olumsuz',
          component: () => import('@/views/raporlar/aday/olumsuzSonucListesi.vue'),
          meta: {
            pageTitle: 'Olumsuz Aday Listesi',
            breadcrumb: [
              {
                text: 'Raporlar',
                active: true,
              },
              {
                text: 'Bekleyen Adaylar',
                active: true,
                to: { name: 'aday-bekler' },
              },
            ],
          },
        },
        {
          path: 'firma-aktif',
          name: 'firma-aktif',
          component: () => import('@/views/raporlar/Firma/AktifFirmaListesi.vue'),
          meta: {
            pageTitle: 'Aktif Firma Listesi',
            breadcrumb: [
              {
                text: 'Raporlar',
                active: true,
              },
              {
                text: 'Aktif Firmalar',
                active: true,
                to: { name: 'firma-aktif' },
              },
            ],
          },
        },
        {
          path: 'talep-aktif',
          name: 'talep-aktif',
          component: () => import('@/views/raporlar/Firma/AktifTalepListesi.vue'),
          meta: {
            pageTitle: 'Aktif Talep Listesi',
            breadcrumb: [
              {
                text: 'Raporlar',
                active: true,
              },
              {
                text: 'Aktif Talepler',
                active: true,
                to: { name: 'talep-aktif' },
              },
            ],
          },
        },
        {
          path: 'firma-talep-listesi',
          name: 'firma-talep-listesi',
          component: () => import('@/views/raporlar/Firma/FirmaTalepListesi.vue'),
          meta: {
            pageTitle: 'Taleplerim',
            breadcrumb: [
              {
                text: 'Raporlar',
                active: true,
              },
              {
                text: 'Taleplerim',
                active: true,
                to: { name: 'firma-talep-listesi' },
              },
            ],
          },
        },
        //        { aday-olumsuz
        //          path: 'aday-bilgilerim',
        //          name: 'aday-bilgilerim',
        //          component: () => import('@/views/islemler/aday/adaybilgilerim.vue'),
        //          props: { },
        //          meta: {
        //            pageTitle: 'Aday İşlemlerim',
        //            breadcrumb: [
        //              {
        //                text: 'Aday İşlemlerim',
        //                active: true,
        //              },
        //              {
        //                text: 'Kayıt Bilgilerim',
        //                active: true,
        //              },
        //            ],
        //          },
        //        },
      ],
    },
    { // ERROR
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
