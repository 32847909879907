/* eslint-disable import/no-cycle */
import request from '@/utils/request'

export function GetStaffRequestPositionExperiences() {
  return request({
    url: '/api/StaffRequestPositionExperiences/GetPositionExperiences',
    method: 'get',
  })
}

export function GetStaffRequestPositionExperiencesById(data) {
  return request({
    url: `/api/StaffRequestPositionExperiences/GetPositionExperienceById/${data}`,
    method: 'get',
  })
}

export function GetStaffRequestPositionExperiencesByRequestId(data) {
  return request({
    url: `/api/StaffRequestPositionExperiences/GetPositionExperienceByRequestId/${data}`,
    method: 'get',
  })
}

export function AddPositionExperience(data) {
  return request({
    url: '/api/StaffRequestPositionExperiences/AddPositionExperience',
    method: 'post',
    data,
  })
}

export function UpdatePositionExperience(data) {
  return request({
    url: '/api/StaffRequestPositionExperiences/UpdatePositionExperience',
    method: 'put',
    data,
  })
}

export function DeletePositionExperience(data) {
  return request({
    url: `/api/StaffRequestPositionExperiences/DeletePositionExperience/${data}`,
    method: 'delete',
  })
}
