/* eslint-disable import/no-cycle */
import * as firmReqApi from '@/api/islemler/firmRequest'
import * as firmReqQualificationApi from '@/api/islemler/staffRequests/staffRequestQualifications'
import * as firmReqCertificationApi from '@/api/islemler/staffRequests/staffRequestCertifications'
import * as firmReqComputerApi from '@/api/islemler/staffRequests/staffRequestComputers'
import * as firmReqForeignLanguageApi from '@/api/islemler/staffRequests/staffRequestForeignLanguages'
import * as firmReqPositionExperienceApi from '@/api/islemler/staffRequests/staffRequestPositionExperiences'
import * as firmReqSectorExperienceApi from '@/api/islemler/staffRequests/staffRequestSectorExperiences'

export default {
  namespaced: true,
  state: {
    firmStaffRequest: {},
    defaultFirmStaffRequest: {
      staffRequestId: 0,
      firmInfoId: 0,
      requestOpenDate: '',
      requestCloseDate: '1900-01-01',
      requestEndDate: '1900-01-01',
      requestStatus: 0,
      staffClass: '',
      position: '',
      factorPositionId: 0,
      shortPositionDescription: '',
      positionDescription: '',
      jobType: '',
      personelCount: 0,
      firmWeekdayMorningWorkingHoursStart: '',
      firmWeekdayMorningWorkingHoursEnd: '',
      firmWeekdayLunchBreakStart: '',
      firmWeekdayLunchBreakEnd: '',
      firmWeekdayOvertimeStart: '',
      firmWeekdayOvertimeEnd: '',
      firmWeekdayOvertimeBreakStart: '',
      firmWeekdayOvertimeBreakEnd: '',
      firmWeekendMorningWorkingHoursStart: '',
      firmWeekendMorningWorkingHoursEnd: '',
      firmWeekendLunchBreakStart: '',
      firmWeekendLunchBreakEnd: '',
      firmWeekendOvertimeStart: '',
      firmWeekendOvertimeEnd: '',
      firmWeekendOvertimeBreakStart: '',
      firmWeekendOvertimeBreakEnd: '',
      salaryMin: 0,
      salaryMax: 0,
      hasPerformanceFee: false,
      hasTransportationVehicle: false,
      hasTransportationSurcharge: false,
      hasTransportationVehicleOrSurcharge: false,
      hasTransportationDependsContract: false,
      hasTransportationUnavailable: false,
      hasMealProvidedInCompany: false,
      hasMealByTicket: false,
      hasMealInCompanyOrTicket: false,
      hasMealSurcharge: false,
      hasMealUnavailable: false,
      hasSocialBonusPayment: false,
      hasSocialChildBenefit: false,
      hasSocialHeatingAid: false,
      hasSocialEducationalAid: false,
      hasSocialIndividualPension: false,
      hasSocialLifeInsurance: false,
      hasSocialUnavailable: false,
      hasSocialOther: false,
      socialOtherDescription: '',
      financialRiskLevelId: null,
      legalRiskLevelId: null,
      medicalRiskLevelId: null,
      lifeRiskLevelId: null,
      gender: null,
      genderImportanceLevelId: null,
      maritalStatus: null,
      maritalImportanceLevelId: null,
      ageMax: 0,
      ageMin: 0,
      ageImportanceLevelId: null,
      weightMax: 0,
      weightMin: 0,
      weightImportanceLevelId: null,
      heightMax: 0,
      heightMin: 0,
      heightImportanceLevelId: null,
      militaryStatus: 0,
      militaryStatusImportanceLevelId: null,
      towns: '',
      townsImportanceLevelId: null,
      districts: '',
      isNotDisabledImportanceLevelId: null,
      isNotSmokerImportanceLevelId: null,
      canTravelImportanceLevelId: null,
      hasWorkShiftsImportanceLevelId: null,
      hasReferencesImportanceLevelId: null,
      hasNotCriminalRecordImportanceLevelId: null,
      canDriveImportanceLevelId: null,
      obstructiveDiseases: [],
      driverLicenseTypeId: null,
      driverLicenseImportanceLevelId: null,
      rowState: 1,
    },
    firmStaffRequestCertification: {},
    defaultFirmStaffRequestCertification: {
      staffRequestCertificateId: 0,
      staffRequestId: 0,
      certificateDescription1: '',
      certificateImportanceLevel1: 0,
      certificateDescription2: '',
      certificateImportanceLevel2: 0,
      certificateDescription3: '',
      certificateImportanceLevel3: 0,
      certificateDescription4: '',
      certificateImportanceLevel4: 0,
      certificateDescription5: '',
      certificateImportanceLevel5: 0,
    },
    firmStaffRequestComputer: {},
    defaultFirmStaffRequestComputer: {
      staffRequestComputerId: 0,
      staffRequestId: 0,
      computerDescription1: '',
      computerImportanceLevel1: 0,
      computerDescription2: '',
      computerImportanceLevel2: 0,
      computerDescription3: '',
      computerImportanceLevel3: 0,
      computerDescription4: '',
      computerImportanceLevel4: 0,
      computerDescription5: '',
      computerImportanceLevel5: 0,
    },
    firmStaffRequestForeignLanguage: {},
    defaultFirmStaffRequestForeignLanguage: {
      staffRequestForeignLanguageId: 0,
      staffRequestId: 0,
      languageDescription1: '',
      languageImportanceLevel1Id: null,
      languageDescription2: '',
      languageImportanceLevel2Id: null,
      languageDescription3: '',
      languageImportanceLevel3Id: null,
      languageDescription4: '',
      languageImportanceLevel4Id: null,
      languageDescription5: '',
      languageImportanceLevel5Id: null,
      rowState: 1,
    },
    firmStaffRequestPositionExperience: {},
    defaultFirmStaffRequestPositionExperience: {
      staffRequestPositionExperienceId: 0,
      staffRequestId: 0,
      positionDescription1: '',
      positionImportanceLevel1: 0,
      yearsOfExperience1: 0,
      positionDescription2: '',
      positionImportanceLevel2: 0,
      yearsOfExperience2: 0,
      positionDescription3: '',
      positionImportanceLevel3: 0,
      yearsOfExperience3: 0,
      positionDescription4: '',
      positionImportanceLevel4: 0,
      yearsOfExperience4: 0,
      positionDescription5: '',
      positionImportanceLevel5: 0,
      yearsOfExperience5: 0,
    },
    firmStaffRequestSectorExperience: {},
    defaultFirmStaffRequestSectorExperience: {
      staffRequestSectorExperienceId: 0,
      staffRequestId: 0,
      sectorDescription1: '',
      sectorImportanceLevel1: 0,
      yearsOfExperience1: 0,
      sectorDescription2: '',
      sectorImportanceLevel2: 0,
      yearsOfExperience2: 0,
      sectorDescription3: '',
      sectorImportanceLevel3: 0,
      yearsOfExperience3: 0,
      sectorDescription4: '',
      sectorImportanceLevel4: 0,
      yearsOfExperience4: 0,
      sectorDescription5: '',
      sectorImportanceLevel5: 0,
      yearsOfExperience5: 0,
    },
    firmStaffRequestQualification: {},
    defaultFirmStaffRequestQualification: {
      staffRequestQualificationId: 0,
      staffRequestId: 0,

      educationLevelId: 0,
      educationLevel: null,
      educationImportanceLevelId: 0,
      educationImportanceLevel: null,

      vocationalHighSchoolDescription: [],
      vocationalHighSchoolImportanceLevelId: 0,
      vocationalHighSchoolImportanceLevel: null,

      vocationalCollegeDescription: [],
      vocationalCollegeImportanceLevelId: 0,
      vocationalCollegeImportanceLevel: null,

      universityDescription: [],
      universityImportanceLevelId: 0,
      universityImportanceLevel: null,

      postgraduateDescription: [],
      postgraduateImportanceLevelId: 0,
      postgraduateImportanceLevel: null,

      yearsOfExperienceId: 0,
      yearsOfExperience: null,

      yearsOfExperienceImportanceLevelId: 0,
      yearsOfExperienceImportanceLevel: null,

      numericalAbilityCompetencyLevelId: 0,
      numericalAbilityCompetencyLevel: null,

      analyticalAbilityCompetencyLevelId: 0,
      analyticalAbilityCompetencyLevel: null,

      attentionAbilityCompetencyLevelId: 0,
      attentionAbilityCompetencyLevel: null,

      learnabilityCompetencyLevelId: 0,
      learnabilityCompetencyLevel: null,

      applyingAbilityCompetencyLevelId: 0,
      applyingAbilityCompetencyLevel: null,

      innovationAbilityCompetencyLevelId: 0,
      innovationAbilityCompetencyLevel: null,

      communicationAbilityCompetencyLevelId: 0,
      communicationAbilityCompetencyLevel: null,

      solutionAbilityCompetencyLevelId: 0,
      solutionAbilityCompetencyLevel: null,

      planningAbilityCompetencyLevelId: 0,
      planningAbilityCompetencyLevel: null,

      coordinationAbilityCompetencyLevelId: 0,
      coordinationAbilityCompetencyLevel: null,

      obeyingAbilityCompetencyLevelId: 0,
      obeyingAbilityCompetencyLevel: null,

      controlAuditingAbilityCompetencyLevelId: 0,
      controlAuditingAbilityCompetencyLevel: null,

      designAbilityCompetencyLevelId: 0,
      designAbilityCompetencyLevel: null,

      representationAbilityCompetencyLevelId: 0,
      representationAbilityCompetencyLevel: null,
      rowState: 0,
    },
  },
  getters: {
    GET_FIRMSTAFFREQUEST: state => state.firmStaffRequest,
    GET_FIRMSTAFFREQUEST_CERTIFICATION: state => state.firmStaffRequestCertification,
    GET_FIRMSTAFFREQUEST_COMPUTER: state => state.firmStaffRequestComputer,
    GET_FIRMSTAFFREQUEST_FOREIGNLANGUAGE: state => state.firmStaffRequestForeignLanguage,
    GET_FIRMSTAFFREQUEST_POSITIONEXPERIENCE: state => state.firmStaffRequestPositionExperience,
    GET_FIRMSTAFFREQUEST_SECTOREXPERIENCE: state => state.firmStaffRequestSectorExperience,
    GET_FIRMSTAFFREQUEST_QUALIFICATION: state => state.firmStaffRequestQualification,
  },
  mutations: {
    SET_FIRMSTAFFREQUEST: (state, firmStaffRequest) => {
      state.firmStaffRequest = firmStaffRequest
    },
    SET_FIRMSTAFFREQUEST_CERTIFICATION: (state, firmStaffRequestCertification) => {
      state.firmStaffRequestCertification = firmStaffRequestCertification
    },
    SET_FIRMSTAFFREQUEST_COMPUTER: (state, firmStaffRequestComputer) => {
      state.firmStaffRequestComputer = firmStaffRequestComputer
    },
    SET_FIRMSTAFFREQUEST_FOREIGNLANGUAGE: (state, firmStaffRequestForeignLanguage) => {
      state.firmStaffRequestForeignLanguage = firmStaffRequestForeignLanguage
    },
    SET_FIRMSTAFFREQUEST_POSITIONEXPERIENCE: (state, firmStaffRequestPositionExperience) => {
      state.firmStaffRequestPositionExperience = firmStaffRequestPositionExperience
    },
    SET_FIRMSTAFFREQUEST_SECTOREXPERIENCE: (state, firmStaffRequestSectorExperience) => {
      state.firmStaffRequestSectorExperience = firmStaffRequestSectorExperience
    },
    SET_FIRMSTAFFREQUEST_QUALIFICATION: (state, firmStaffRequestQualification) => {
      state.firmStaffRequestQualification = firmStaffRequestQualification
    },
    RESET_FIRMSTAFFREQUEST: state => {
      state.firmStaffRequest = { ...state.defaultFirmStaffRequest }
    },
    RESET_FIRMSTAFFREQUEST_CERTIFICATION: state => {
      state.firmStaffRequestCertification = { ...state.defaultFirmStaffRequestCertification }
    },
    RESET_FIRMSTAFFREQUEST_COMPUTER: state => {
      state.firmStaffRequestComputer = { ...state.defaultFirmStaffRequestComputer }
    },
    RESET_FIRMSTAFFREQUEST_FOREIGNLANGUAGE: state => {
      state.firmStaffRequestForeignLanguage = { ...state.defaultFirmStaffRequestForeignLanguage }
    },
    RESET_FIRMSTAFFREQUEST_POSITIONEXPERIENCE: state => {
      state.firmStaffRequestPositionExperience = { ...state.defaultFirmStaffRequestPositionExperience }
    },
    RESET_FIRMSTAFFREQUEST_SECTOREXPERIENCE: state => {
      state.firmStaffRequestSectorExperience = { ...state.defaultFirmStaffRequestSectorExperience }
    },
    RESET_FIRMSTAFFREQUEST_QUALIFICATION: state => {
      state.firmStaffRequestQualification = { ...state.defaultFirmStaffRequestQualification }
    },
  },
  actions: {
    action_get_firmStaffRequest({ commit, state }, payload) {
      commit('RESET_FIRMSTAFFREQUEST')
      return new Promise((resolve, reject) => {
        if (payload === -1) {
          commit('RESET_FIRMSTAFFREQUEST')
          resolve(true)
          return
        }
        firmReqApi.GetStaffRequestById(payload).then(resp => {
          if (resp.data.entity == null) {
            commit('RESET_FIRMSTAFFREQUEST', state.defaultFirmStaffRequest)
          } else {
            commit('SET_FIRMSTAFFREQUEST', resp.data.entity)
          }
          resolve(true)
        }).catch(error => {
          commit('RESET_FIRMSTAFFREQUEST')
          reject(error)
        })
      })
    },
    action_save_firmStaffRequest({ state }) {
      return new Promise((resolve, reject) => {
        let result = -1
        if (state.firmStaffRequest.financialRiskLevel === null) {
          state.firmStaffRequest.financialRiskLevelId = null
        } else if (typeof (state.firmStaffRequest.financialRiskLevel) === 'object') {
          state.firmStaffRequest.financialRiskLevelId = state.firmStaffRequest.financialRiskLevel.riskLevelId
        }

        if (state.firmStaffRequest.legalRiskLevel === null) {
          state.firmStaffRequest.legalRiskLevelId = null
        } else if (typeof (state.firmStaffRequest.legalRiskLevel) === 'object') {
          state.firmStaffRequest.legalRiskLevelId = state.firmStaffRequest.legalRiskLevel.riskLevelId
        }

        if (state.firmStaffRequest.medicalRiskLevel === null) {
          state.firmStaffRequest.medicalRiskLevelId = null
        } else if (typeof (state.firmStaffRequest.medicalRiskLevel) === 'object') {
          state.firmStaffRequest.medicalRiskLevelId = state.firmStaffRequest.medicalRiskLevel.riskLevelId
        }

        if (state.firmStaffRequest.lifeRiskLevel === null) {
          state.firmStaffRequest.lifeRiskLevelId = null
        } else if (typeof (state.firmStaffRequest.lifeRiskLevel) === 'object') {
          state.firmStaffRequest.lifeRiskLevelId = state.firmStaffRequest.lifeRiskLevel.riskLevelId
        }

        if (state.firmStaffRequest.driverLicenseType === null) {
          state.firmStaffRequest.driverLicenseTypeId = null
        } else if (typeof (state.firmStaffRequest.driverLicenseType) === 'object') {
          state.firmStaffRequest.driverLicenseTypeId = state.firmStaffRequest.driverLicenseType.driverLicenceTypeId
        }

        if (state.firmStaffRequest.driverLicenseImportanceLevel === null) {
          state.firmStaffRequest.driverLicenseImportanceLevelId = null
        } else if (typeof (state.firmStaffRequest.driverLicenseImportanceLevel) === 'object') {
          state.firmStaffRequest.driverLicenseImportanceLevelId = state.firmStaffRequest.driverLicenseImportanceLevel.importanceLevelId
        }

        state.firmStaffRequest.financialRiskLevel = null
        state.firmStaffRequest.legalRiskLevel = null
        state.firmStaffRequest.medicalRiskLevel = null
        state.firmStaffRequest.lifeRiskLevel = null

        state.firmStaffRequest.genderImportanceLevel = null
        state.firmStaffRequest.maritalImportanceLevel = null
        state.firmStaffRequest.townsImportanceLevel = null
        state.firmStaffRequest.ageImportanceLevel = null
        state.firmStaffRequest.weightImportanceLevel = null
        state.firmStaffRequest.heightImportanceLevel = null
        state.firmStaffRequest.militaryStatusImportanceLevel = null
        state.firmStaffRequest.isNotDisabledImportanceLevel = null
        state.firmStaffRequest.isNotSmokerImportanceLevel = null
        state.firmStaffRequest.canTravelImportanceLevel = null
        state.firmStaffRequest.hasWorkShiftsImportanceLevel = null
        state.firmStaffRequest.hasReferencesImportanceLevel = null
        state.firmStaffRequest.hasNotCriminalRecordImportanceLevel = null
        state.firmStaffRequest.canDriveImportanceLevel = null
        state.firmStaffRequest.driverLicenseImportanceLevel = null

        if (state.firmStaffRequest.staffRequestId === 0) {
          const d = new Date(Date.now())
          state.firmStaffRequest.requestOpenDate = d.toISOString()
          firmReqApi.AddStaffRequest(state.firmStaffRequest).then(resp => {
            result = resp.data.entity
            // commit('SET_FIRMSTAFFREQUEST', resp.data.entity)
            resolve(result)
          })
          return result
        }
        if (state.firmStaffRequest.staffRequestId > 0) {
          firmReqApi.UpdateStaffRequest(state.firmStaffRequest).then(resp => {
            result = resp.data.entity
            resolve(result)
          })
          return result
        }
        reject()
        return result
      })
    },
    action_get_firmStaffRequestCertification({ commit, state }, payload) {
      commit('RESET_FIRMSTAFFREQUEST_CERTIFICATION')
      return new Promise((resolve, reject) => {
        if (payload === -1) {
          commit('SET_FIRMSTAFFREQUEST_CERTIFICATION', state.defaultFirmStaffRequestCertification)
          resolve(true)
          return
        }
        firmReqCertificationApi.GetStaffRequestCeritificationByRequestId(payload).then(resp => {
          if (resp.data.entity == null) {
            commit('SET_FIRMSTAFFREQUEST_CERTIFICATION', state.defaultFirmStaffRequestCertification)
          } else {
            commit('SET_FIRMSTAFFREQUEST_CERTIFICATION', resp.data.entity)
          }
          resolve(true)
        }).catch(error => {
          commit('SET_FIRMSTAFFREQUEST_CERTIFICATION', {})
          reject(error)
        })
      })
    },
    action_save_firmStaffRequestCertification({ commit, state }) {
      return new Promise((resolve, reject) => {
        let result = -1
        if (state.firmStaffRequestCertification.certificateImportanceLevel1 === null) {
          state.firmStaffRequestCertification.certificateImportanceLevel1Id = null
        } else if (typeof (state.firmStaffRequestCertification.certificateImportanceLevel1) === 'object') {
          state.firmStaffRequestCertification.certificateImportanceLevel1Id = state.firmStaffRequestCertification.certificateImportanceLevel1.importanceLevelId
        } else {
          state.firmStaffRequestCertification.certificateImportanceLevel1Id = state.firmStaffRequestCertification.certificateImportanceLevel1.importanceLevelId
        }

        if (state.firmStaffRequestCertification.certificateImportanceLevel2 === null) {
          state.firmStaffRequestCertification.certificateImportanceLevel2Id = null
        } else if (typeof (state.firmStaffRequestCertification.certificateImportanceLevel2) === 'object') {
          state.firmStaffRequestCertification.certificateImportanceLevel2Id = state.firmStaffRequestCertification.certificateImportanceLevel2.importanceLevelId
        } else {
          state.firmStaffRequestCertification.certificateImportanceLevel2Id = state.firmStaffRequestCertification.certificateImportanceLevel2.importanceLevelId
        }

        if (state.firmStaffRequestCertification.certificateImportanceLevel3 === null) {
          state.firmStaffRequestCertification.certificateImportanceLevel3Id = null
        } else if (typeof (state.firmStaffRequestCertification.certificateImportanceLevel3) === 'object') {
          state.firmStaffRequestCertification.certificateImportanceLevel3Id = state.firmStaffRequestCertification.certificateImportanceLevel3.importanceLevelId
        } else {
          state.firmStaffRequestCertification.certificateImportanceLevel3Id = state.firmStaffRequestCertification.certificateImportanceLevel3.importanceLevelId
        }

        if (state.firmStaffRequestCertification.certificateImportanceLevel4 === null) {
          state.firmStaffRequestCertification.certificateImportanceLevel4Id = null
        } else if (typeof (state.firmStaffRequestCertification.certificateImportanceLevel4) === 'object') {
          state.firmStaffRequestCertification.certificateImportanceLevel4Id = state.firmStaffRequestCertification.certificateImportanceLevel4.importanceLevelId
        } else {
          state.firmStaffRequestCertification.certificateImportanceLevel4Id = state.firmStaffRequestCertification.certificateImportanceLevel4.importanceLevelId
        }

        if (state.firmStaffRequestCertification.certificateImportanceLevel5 === null) {
          state.firmStaffRequestCertification.certificateImportanceLevel5Id = null
        } else if (typeof (state.firmStaffRequestCertification.certificateImportanceLevel5) === 'object') {
          state.firmStaffRequestCertification.certificateImportanceLevel5Id = state.firmStaffRequestCertification.certificateImportanceLevel5.importanceLevelId
        } else {
          state.firmStaffRequestCertification.certificateImportanceLevel5Id = state.firmStaffRequestCertification.certificateImportanceLevel5.importanceLevelId
        }

        state.firmStaffRequestCertification.certificateImportanceLevel1 = null
        state.firmStaffRequestCertification.certificateImportanceLevel2 = null
        state.firmStaffRequestCertification.certificateImportanceLevel3 = null
        state.firmStaffRequestCertification.certificateImportanceLevel4 = null
        state.firmStaffRequestCertification.certificateImportanceLevel5 = null

        firmReqCertificationApi.GetStaffRequestCeritificationByRequestId(state.firmStaffRequest.staffRequestId).then(res => {
          if (res.data.entity == null) state.firmStaffRequestCertification.staffRequestCertificateId = 0
          else state.firmStaffRequestCertification.staffRequestCertificateId = res.data.entity.staffRequestCertificateId

          if (state.firmStaffRequestCertification.staffRequestCertificateId === 0) {
            firmReqCertificationApi.AddStaffRequestCertification(state.firmStaffRequestCertification).then(resp => {
              result = resp.data.entity
              commit('SET_FIRMSTAFFREQUEST_CERTIFICATION', resp.data.entity)
              resolve(result)
            })
            return result
          }
          if (state.firmStaffRequestCertification.staffRequestCertificateId > 0) {
            firmReqCertificationApi.UpdateStaffRequestCertification(state.firmStaffRequestCertification).then(resp => {
              result = resp.data.entity
              resolve(result)
            })
            return result
          }
          reject()
          return result
        })
      })
    },
    action_get_firmStaffRequestComputer({ commit, state }, payload) {
      commit('RESET_FIRMSTAFFREQUEST_COMPUTER')
      return new Promise((resolve, reject) => {
        if (payload === -1) {
          commit('SET_FIRMSTAFFREQUEST_COMPUTER', state.defaultFirmStaffRequestComputer)
          resolve(true)
          return
        }
        firmReqComputerApi.GetStaffRequestComputerByRequestId(payload).then(resp => {
          if (resp.data.entity == null) {
            commit('SET_FIRMSTAFFREQUEST_COMPUTER', state.defaultFirmStaffRequestComputer)
          } else {
            commit('SET_FIRMSTAFFREQUEST_COMPUTER', resp.data.entity)
          }
          resolve(true)
        }).catch(error => {
          commit('SET_FIRMSTAFFREQUEST_COMPUTER', {})
          reject(error)
        })
      })
    },
    action_save_firmStaffRequestComputer({ commit, state }) {
      return new Promise((resolve, reject) => {
        let result = -1
        if (state.firmStaffRequestComputer.computerImportanceLevel1 === null) {
          state.firmStaffRequestComputer.computerImportanceLevel1Id = null
        } else if (typeof (state.firmStaffRequestComputer.computerImportanceLevel1) === 'object') {
          state.firmStaffRequestComputer.computerImportanceLevel1Id = state.firmStaffRequestComputer.computerImportanceLevel1.importanceLevelId
        }

        if (state.firmStaffRequestComputer.computerImportanceLevel2 === null) {
          state.firmStaffRequestComputer.computerImportanceLevel2Id = null
        } else if (typeof (state.firmStaffRequestComputer.computerImportanceLevel2) === 'object') {
          state.firmStaffRequestComputer.computerImportanceLevel2Id = state.firmStaffRequestComputer.computerImportanceLevel2.importanceLevelId
        }

        if (state.firmStaffRequestComputer.computerImportanceLevel3 === null) {
          state.firmStaffRequestComputer.computerImportanceLevel3Id = null
        } else if (typeof (state.firmStaffRequestComputer.computerImportanceLevel3) === 'object') {
          state.firmStaffRequestComputer.computerImportanceLevel3Id = state.firmStaffRequestComputer.computerImportanceLevel3.importanceLevelId
        }

        if (state.firmStaffRequestComputer.computerImportanceLevel4 === null) {
          state.firmStaffRequestComputer.computerImportanceLevel4Id = null
        } else if (typeof (state.firmStaffRequestComputer.computerImportanceLevel4) === 'object') {
          state.firmStaffRequestComputer.computerImportanceLevel4Id = state.firmStaffRequestComputer.computerImportanceLevel4.importanceLevelId
        }

        if (state.firmStaffRequestComputer.computerImportanceLevel5 === null) {
          state.firmStaffRequestComputer.computerImportanceLevel5Id = null
        } else if (typeof (state.firmStaffRequestComputer.computerImportanceLevel5) === 'object') {
          state.firmStaffRequestComputer.computerImportanceLevel5Id = state.firmStaffRequestComputer.computerImportanceLevel5.importanceLevelId
        }

        state.firmStaffRequestComputer.computerImportanceLevel1 = null
        state.firmStaffRequestComputer.computerImportanceLevel2 = null
        state.firmStaffRequestComputer.computerImportanceLevel3 = null
        state.firmStaffRequestComputer.computerImportanceLevel4 = null
        state.firmStaffRequestComputer.computerImportanceLevel5 = null

        firmReqComputerApi.GetStaffRequestComputerByRequestId(state.firmStaffRequestComputer.staffRequestId).then(res => {
          if (res.data.entity == null) state.firmStaffRequestComputer.staffRequestComputerId = 0
          else state.firmStaffRequestComputer.staffRequestComputerId = res.data.entity.staffRequestComputerId
          if (state.firmStaffRequestComputer.staffRequestComputerId === 0) {
            firmReqComputerApi.AddStaffRequestComputer(state.firmStaffRequestComputer).then(resp => {
              result = resp.data.entity
              commit('SET_FIRMSTAFFREQUEST_COMPUTER', resp.data.entity)
              resolve(result)
            })
            return result
          }
          if (state.firmStaffRequestComputer.staffRequestComputerId > 0) {
            firmReqComputerApi.UpdateStaffRequestComputer(state.firmStaffRequestComputer).then(resp => {
              result = resp.data.entity
              resolve(result)
            })
            return result
          }
          reject()
          return result
        })
      })
    },
    action_get_firmStaffRequestForeignLanguage({ commit, state }, payload) {
      commit('RESET_FIRMSTAFFREQUEST_FOREIGNLANGUAGE')
      return new Promise((resolve, reject) => {
        if (payload === -1) {
          commit('SET_FIRMSTAFFREQUEST_FOREIGNLANGUAGE', state.defaultFirmStaffRequestForeignLanguage)
          resolve(true)
          return
        }
        firmReqForeignLanguageApi.GetStaffRequestForeignLanguagesByRequestId(payload).then(resp => {
          if (resp.data.entity == null) {
            commit('SET_FIRMSTAFFREQUEST_FOREIGNLANGUAGE', state.defaultFirmStaffRequestForeignLanguage)
          } else {
            commit('SET_FIRMSTAFFREQUEST_FOREIGNLANGUAGE', resp.data.entity)
          }
          resolve(true)
        }).catch(error => {
          commit('SET_FIRMSTAFFREQUEST_FOREIGNLANGUAGE', {})
          reject(error)
        })
      })
    },
    action_save_firmStaffRequestForeignLanguage({ commit, state }) {
      return new Promise((resolve, reject) => {
        let result = -1
        if (state.firmStaffRequestForeignLanguage.languageImportanceLevel1 === null) {
          state.firmStaffRequestForeignLanguage.languageImportanceLevel1Id = null
        } else if (typeof (state.firmStaffRequestForeignLanguage.languageImportanceLevel1) === 'object') {
          state.firmStaffRequestForeignLanguage.languageImportanceLevel1Id = state.firmStaffRequestForeignLanguage.languageImportanceLevel1.importanceLevelId
        }

        if (state.firmStaffRequestForeignLanguage.languageImportanceLevel2 === null) {
          state.firmStaffRequestForeignLanguage.languageImportanceLevel2Id = null
        } else if (typeof (state.firmStaffRequestForeignLanguage.languageImportanceLevel2) === 'object') {
          state.firmStaffRequestForeignLanguage.languageImportanceLevel2Id = state.firmStaffRequestForeignLanguage.languageImportanceLevel2.importanceLevelId
        }

        if (state.firmStaffRequestForeignLanguage.languageImportanceLevel3 === null) {
          state.firmStaffRequestForeignLanguage.languageImportanceLevel3Id = null
        } else if (typeof (state.firmStaffRequestForeignLanguage.languageImportanceLevel3) === 'object') {
          state.firmStaffRequestForeignLanguage.languageImportanceLevel3Id = state.firmStaffRequestForeignLanguage.languageImportanceLevel3.importanceLevelId
        }

        if (state.firmStaffRequestForeignLanguage.languageImportanceLevel4 === null) {
          state.firmStaffRequestForeignLanguage.languageImportanceLevel4Id = null
        } else if (typeof (state.firmStaffRequestForeignLanguage.languageImportanceLevel4) === 'object') {
          state.firmStaffRequestForeignLanguage.languageImportanceLevel4Id = state.firmStaffRequestForeignLanguage.languageImportanceLevel4.importanceLevelId
        }

        if (state.firmStaffRequestForeignLanguage.languageImportanceLevel5 === null) {
          state.firmStaffRequestForeignLanguage.languageImportanceLevel5Id = null
        } else if (typeof (state.firmStaffRequestForeignLanguage.languageImportanceLevel5) === 'object') {
          state.firmStaffRequestForeignLanguage.languageImportanceLevel5Id = state.firmStaffRequestForeignLanguage.languageImportanceLevel5.importanceLevelId
        }

        state.firmStaffRequestForeignLanguage.languageImportanceLevel1 = null
        state.firmStaffRequestForeignLanguage.languageImportanceLevel2 = null
        state.firmStaffRequestForeignLanguage.languageImportanceLevel3 = null
        state.firmStaffRequestForeignLanguage.languageImportanceLevel4 = null
        state.firmStaffRequestForeignLanguage.languageImportanceLevel5 = null

        firmReqForeignLanguageApi.GetStaffRequestForeignLanguagesByRequestId(state.firmStaffRequestForeignLanguage.staffRequestId).then(res => {
          if (res.data.entity == null) state.firmStaffRequestForeignLanguage.staffRequestForeignLanguageId = 0
          else state.firmStaffRequestForeignLanguage.staffRequestForeignLanguageId = res.data.entity.staffRequestForeignLanguageId

          if (state.firmStaffRequestForeignLanguage.staffRequestForeignLanguageId === 0) {
            firmReqForeignLanguageApi.AddStaffRequestForeignLanguage(state.firmStaffRequestForeignLanguage).then(resp => {
              result = resp.data.entity
              commit('SET_FIRMSTAFFREQUEST_FOREIGNLANGUAGE', resp.data.entity)
              resolve(result)
            })
            return result
          }
          if (state.firmStaffRequestForeignLanguage.staffRequestForeignLanguageId > 0) {
            firmReqForeignLanguageApi.UpdateStaffRequestForeignLanguage(state.firmStaffRequestForeignLanguage).then(resp => {
              result = resp.data.entity
              resolve(result)
            })
            return result
          }
          reject()
          return result
        })
      })
    },
    action_get_firmStaffRequestPositionExperience({ commit, state }, payload) {
      commit('RESET_FIRMSTAFFREQUEST_POSITIONEXPERIENCE')
      return new Promise((resolve, reject) => {
        if (payload === -1) {
          commit('SET_FIRMSTAFFREQUEST_POSITIONEXPERIENCE', state.defaultFirmStaffRequestPositionExperience)
          resolve(true)
          return
        }
        firmReqPositionExperienceApi.GetStaffRequestPositionExperiencesByRequestId(payload).then(resp => {
          if (resp.data.entity == null) {
            commit('SET_FIRMSTAFFREQUEST_POSITIONEXPERIENCE', state.defaultFirmStaffRequestPositionExperience)
          } else {
            commit('SET_FIRMSTAFFREQUEST_POSITIONEXPERIENCE', resp.data.entity)
          }
          resolve(true)
        }).catch(error => {
          commit('SET_FIRMSTAFFREQUEST_POSITIONEXPERIENCE', {})
          reject(error)
        })
      })
    },
    action_save_firmStaffRequestPositionExperience({ commit, state }) {
      return new Promise((resolve, reject) => {
        let result = -1
        if (state.firmStaffRequestPositionExperience.positionImportanceLevel1 === null) {
          state.firmStaffRequestPositionExperience.positionImportanceLevel1Id = null
        } else if (typeof (state.firmStaffRequestPositionExperience.positionImportanceLevel1) === 'object') {
          state.firmStaffRequestPositionExperience.positionImportanceLevel1Id = state.firmStaffRequestPositionExperience.positionImportanceLevel1.importanceLevelId
        }
        if (state.firmStaffRequestPositionExperience.positionImportanceLevel2 === null) {
          state.firmStaffRequestPositionExperience.positionImportanceLevel2Id = null
        } else if (typeof (state.firmStaffRequestPositionExperience.positionImportanceLevel2) === 'object') {
          state.firmStaffRequestPositionExperience.positionImportanceLevel2Id = state.firmStaffRequestPositionExperience.positionImportanceLevel2.importanceLevelId
        }
        if (state.firmStaffRequestPositionExperience.positionImportanceLevel3 === null) {
          state.firmStaffRequestPositionExperience.positionImportanceLevel3Id = null
        } else if (typeof (state.firmStaffRequestPositionExperience.positionImportanceLevel3) === 'object') {
          state.firmStaffRequestPositionExperience.positionImportanceLevel3Id = state.firmStaffRequestPositionExperience.positionImportanceLevel3.importanceLevelId
        }
        if (state.firmStaffRequestPositionExperience.positionImportanceLevel4 === null) {
          state.firmStaffRequestPositionExperience.positionImportanceLevel4Id = null
        } else if (typeof (state.firmStaffRequestPositionExperience.positionImportanceLevel4) === 'object') {
          state.firmStaffRequestPositionExperience.positionImportanceLevel4Id = state.firmStaffRequestPositionExperience.positionImportanceLevel4.importanceLevelId
        }
        if (state.firmStaffRequestPositionExperience.positionImportanceLevel5 === null) {
          state.firmStaffRequestPositionExperience.positionImportanceLevel5Id = null
        } else if (typeof (state.firmStaffRequestPositionExperience.positionImportanceLevel5) === 'object') {
          state.firmStaffRequestPositionExperience.positionImportanceLevel5Id = state.firmStaffRequestPositionExperience.positionImportanceLevel5.importanceLevelId
        }

        if (state.firmStaffRequestPositionExperience.yearsOfExperience1 === null) {
          state.firmStaffRequestPositionExperience.yearsOfExperience1Id = null
        } else if (typeof (state.firmStaffRequestPositionExperience.yearsOfExperience1) === 'object') {
          state.firmStaffRequestPositionExperience.yearsOfExperience1Id = state.firmStaffRequestPositionExperience.yearsOfExperience1.experienceYearId
        }
        if (state.firmStaffRequestPositionExperience.yearsOfExperience2 === null) {
          state.firmStaffRequestPositionExperience.yearsOfExperience2Id = null
        } else if (typeof (state.firmStaffRequestPositionExperience.yearsOfExperience2) === 'object') {
          state.firmStaffRequestPositionExperience.yearsOfExperience2Id = state.firmStaffRequestPositionExperience.yearsOfExperience2.experienceYearId
        }
        if (state.firmStaffRequestPositionExperience.yearsOfExperience3 === null) {
          state.firmStaffRequestPositionExperience.yearsOfExperience3Id = null
        } else if (typeof (state.firmStaffRequestPositionExperience.yearsOfExperience3) === 'object') {
          state.firmStaffRequestPositionExperience.yearsOfExperience3Id = state.firmStaffRequestPositionExperience.yearsOfExperience3.experienceYearId
        }
        if (state.firmStaffRequestPositionExperience.yearsOfExperience4 === null) {
          state.firmStaffRequestPositionExperience.yearsOfExperience4Id = null
        } else if (typeof (state.firmStaffRequestPositionExperience.yearsOfExperience4) === 'object') {
          state.firmStaffRequestPositionExperience.yearsOfExperience4Id = state.firmStaffRequestPositionExperience.yearsOfExperience4.experienceYearId
        }
        if (state.firmStaffRequestPositionExperience.yearsOfExperience5 === null) {
          state.firmStaffRequestPositionExperience.yearsOfExperience5Id = null
        } else if (typeof (state.firmStaffRequestPositionExperience.yearsOfExperience5) === 'object') {
          state.firmStaffRequestPositionExperience.yearsOfExperience5Id = state.firmStaffRequestPositionExperience.yearsOfExperience5.experienceYearId
        }
        state.firmStaffRequestPositionExperience.yearsOfExperience1 = null
        state.firmStaffRequestPositionExperience.positionImportanceLevel1 = null
        state.firmStaffRequestPositionExperience.yearsOfExperience2 = null
        state.firmStaffRequestPositionExperience.positionImportanceLevel2 = null
        state.firmStaffRequestPositionExperience.yearsOfExperience3 = null
        state.firmStaffRequestPositionExperience.positionImportanceLevel3 = null
        state.firmStaffRequestPositionExperience.yearsOfExperience4 = null
        state.firmStaffRequestPositionExperience.positionImportanceLevel4 = null
        state.firmStaffRequestPositionExperience.yearsOfExperience5 = null
        state.firmStaffRequestPositionExperience.positionImportanceLevel5 = null

        firmReqPositionExperienceApi.GetStaffRequestPositionExperiencesByRequestId(state.firmStaffRequestPositionExperience.staffRequestId).then(res => {
          if (res.data.entity == null) state.firmStaffRequestPositionExperience.staffRequestPositionExperienceId = 0
          else state.firmStaffRequestPositionExperience.staffRequestPositionExperienceId = res.data.entity.staffRequestPositionExperienceId

          if (state.firmStaffRequestPositionExperience.staffRequestPositionExperienceId === 0) {
            firmReqPositionExperienceApi.AddPositionExperience(state.firmStaffRequestPositionExperience).then(resp => {
              result = resp.data.entity
              commit('SET_FIRMSTAFFREQUEST_POSITIONEXPERIENCE', resp.data.entity)
              resolve(result)
            })
            return result
          }
          if (state.firmStaffRequestPositionExperience.staffRequestPositionExperienceId > 0) {
            firmReqPositionExperienceApi.UpdatePositionExperience(state.firmStaffRequestPositionExperience).then(resp => {
              result = resp.data.entity
              resolve(result)
            })
            return result
          }
          reject()
          return result
        })
      })
    },
    action_get_firmStaffRequestSectorExperience({ commit, state }, payload) {
      commit('RESET_FIRMSTAFFREQUEST_SECTOREXPERIENCE')
      return new Promise((resolve, reject) => {
        if (payload === -1) {
          commit('SET_FIRMSTAFFREQUEST_SECTOREXPERIENCE', state.defaultFirmStaffRequestSectorExperience)
          resolve(true)
          return
        }
        firmReqSectorExperienceApi.GetStaffRequestSectorExperienceByRequestId(payload).then(resp => {
          if (resp.data.entity == null) {
            commit('SET_FIRMSTAFFREQUEST_SECTOREXPERIENCE', state.defaultFirmStaffRequestSectorExperience)
          } else {
            commit('SET_FIRMSTAFFREQUEST_SECTOREXPERIENCE', resp.data.entity)
          }
          resolve(true)
        }).catch(error => {
          commit('SET_FIRMSTAFFREQUEST_SECTOREXPERIENCE', {})
          reject(error)
        })
      })
    },
    action_save_firmStaffRequestSectorExperience({ commit, state }) {
      return new Promise((resolve, reject) => {
        let result = -1
        if (state.firmStaffRequestSectorExperience.yearsOfExperience1 === null) {
          state.firmStaffRequestSectorExperience.yearsOfExperience1Id = null
        } else if (typeof (state.firmStaffRequestSectorExperience.yearsOfExperience1) === 'object') {
          state.firmStaffRequestSectorExperience.yearsOfExperience1Id = state.firmStaffRequestSectorExperience.yearsOfExperience1.experienceYearId
        }
        if (state.firmStaffRequestSectorExperience.sectorImportanceLevel1 === null) {
          state.firmStaffRequestSectorExperience.sectorImportanceLevel1Id = null
        } else if (typeof (state.firmStaffRequestSectorExperience.sectorImportanceLevel1) === 'object') {
          state.firmStaffRequestSectorExperience.sectorImportanceLevel1Id = state.firmStaffRequestSectorExperience.sectorImportanceLevel2.importanceLevelId
        }

        if (state.firmStaffRequestSectorExperience.yearsOfExperience2 === null) {
          state.firmStaffRequestSectorExperience.yearsOfExperience2Id = null
        } else if (typeof (state.firmStaffRequestSectorExperience.yearsOfExperience2) === 'object') {
          state.firmStaffRequestSectorExperience.yearsOfExperience2Id = state.firmStaffRequestSectorExperience.yearsOfExperience2.experienceYearId
        }
        if (state.firmStaffRequestSectorExperience.sectorImportanceLevel2 === null) {
          state.firmStaffRequestSectorExperience.sectorImportanceLevel2Id = null
        } else if (typeof (state.firmStaffRequestSectorExperience.sectorImportanceLevel2) === 'object') {
          state.firmStaffRequestSectorExperience.sectorImportanceLevel2Id = state.firmStaffRequestSectorExperience.sectorImportanceLevel2.importanceLevelId
        }

        if (state.firmStaffRequestSectorExperience.yearsOfExperience3 === null) {
          state.firmStaffRequestSectorExperience.yearsOfExperience3Id = null
        } else if (typeof (state.firmStaffRequestSectorExperience.yearsOfExperience3) === 'object') {
          state.firmStaffRequestSectorExperience.yearsOfExperience3Id = state.firmStaffRequestSectorExperience.yearsOfExperience3.experienceYearId
        }
        if (state.firmStaffRequestSectorExperience.sectorImportanceLevel3 === null) {
          state.firmStaffRequestSectorExperience.sectorImportanceLevel3Id = null
        } else if (typeof (state.firmStaffRequestSectorExperience.sectorImportanceLevel3) === 'object') {
          state.firmStaffRequestSectorExperience.sectorImportanceLevel3Id = state.firmStaffRequestSectorExperience.sectorImportanceLevel3.importanceLevelId
        }

        if (state.firmStaffRequestSectorExperience.yearsOfExperience4 === null) {
          state.firmStaffRequestSectorExperience.yearsOfExperience4Id = null
        } else if (typeof (state.firmStaffRequestSectorExperience.yearsOfExperience4) === 'object') {
          state.firmStaffRequestSectorExperience.yearsOfExperience4Id = state.firmStaffRequestSectorExperience.yearsOfExperience4.experienceYearId
        }
        if (state.firmStaffRequestSectorExperience.sectorImportanceLevel4 === null) {
          state.firmStaffRequestSectorExperience.sectorImportanceLevel4Id = null
        } else if (typeof (state.firmStaffRequestSectorExperience.sectorImportanceLevel4) === 'object') {
          state.firmStaffRequestSectorExperience.sectorImportanceLevel4Id = state.firmStaffRequestSectorExperience.sectorImportanceLevel4.importanceLevelId
        }

        if (state.firmStaffRequestSectorExperience.yearsOfExperience5 === null) {
          state.firmStaffRequestSectorExperience.yearsOfExperience5Id = null
        } else if (typeof (state.firmStaffRequestSectorExperience.yearsOfExperience5) === 'object') {
          state.firmStaffRequestSectorExperience.yearsOfExperience5Id = state.firmStaffRequestSectorExperience.yearsOfExperience5.experienceYearId
        }
        if (state.firmStaffRequestSectorExperience.sectorImportanceLevel5 === null) {
          state.firmStaffRequestSectorExperience.sectorImportanceLevel5Id = null
        } else if (typeof (state.firmStaffRequestSectorExperience.sectorImportanceLevel5) === 'object') {
          state.firmStaffRequestSectorExperience.sectorImportanceLevel5Id = state.firmStaffRequestSectorExperience.sectorImportanceLevel5.importanceLevelId
        }

        state.firmStaffRequestSectorExperience.yearsOfExperience1 = null
        state.firmStaffRequestSectorExperience.sectorImportanceLevel1 = null
        state.firmStaffRequestSectorExperience.yearsOfExperience2 = null
        state.firmStaffRequestSectorExperience.sectorImportanceLevel2 = null
        state.firmStaffRequestSectorExperience.yearsOfExperience3 = null
        state.firmStaffRequestSectorExperience.sectorImportanceLevel3 = null
        state.firmStaffRequestSectorExperience.yearsOfExperience4 = null
        state.firmStaffRequestSectorExperience.sectorImportanceLevel4 = null
        state.firmStaffRequestSectorExperience.yearsOfExperience5 = null
        state.firmStaffRequestSectorExperience.sectorImportanceLevel5 = null

        firmReqSectorExperienceApi.GetStaffRequestSectorExperienceByRequestId(state.firmStaffRequestSectorExperience.staffRequestId).then(res => {
          if (res.data.entity == null) state.firmStaffRequestSectorExperience.staffRequestSectorExperienceId = 0
          else state.firmStaffRequestSectorExperience.staffRequestSectorExperienceId = res.data.entity.staffRequestSectorExperienceId

          if (state.firmStaffRequestSectorExperience.staffRequestSectorExperienceId === 0) {
            firmReqSectorExperienceApi.AddStaffRequestSectorExperience(state.firmStaffRequestSectorExperience).then(resp => {
              result = resp.data.entity
              commit('SET_FIRMSTAFFREQUEST_SECTOREXPERIENCE', resp.data.entity)
              resolve(result)
            })
            return result
          }
          if (state.firmStaffRequestSectorExperience.staffRequestSectorExperienceId > 0) {
            firmReqSectorExperienceApi.UpdateStaffRequestSectorExperience(state.firmStaffRequestSectorExperience).then(resp => {
              result = resp.data.entity
              resolve(result)
            })
            return result
          }
          reject()
          return result
        })
      })
    },
    action_get_firmStaffRequestQualification({ commit, state }, payload) {
      commit('RESET_FIRMSTAFFREQUEST_QUALIFICATION')
      return new Promise((resolve, reject) => {
        if (payload === -1) {
          commit('SET_FIRMSTAFFREQUEST_QUALIFICATION', state.defaultFirmStaffRequestQualification)
          resolve(true)
          return
        }
        firmReqQualificationApi.GetStaffQualificationByRequestId(payload).then(resp => {
          if (resp.data.entity == null) {
            commit('SET_FIRMSTAFFREQUEST_QUALIFICATION', state.defaultFirmStaffRequestQualification)
          } else {
            commit('SET_FIRMSTAFFREQUEST_QUALIFICATION', resp.data.entity)
          }
          resolve(true)
        }).catch(error => {
          commit('SET_FIRMSTAFFREQUEST_QUALIFICATION', {})
          reject(error)
        })
      })
    },
    action_save_firmStaffRequestQualification({ commit, state }) {
      return new Promise((resolve, reject) => {
        let result = -1
        if (state.firmStaffRequestQualification.numericalAbilityCompetencyLevel === null) {
          state.firmStaffRequestQualification.numericalAbilityCompetencyLevelId = null
        } else if (typeof (state.firmStaffRequestQualification.numericalAbilityCompetencyLevel) === 'object') {
          state.firmStaffRequestQualification.numericalAbilityCompetencyLevelId = state.firmStaffRequestQualification.numericalAbilityCompetencyLevel.technicalBasicCompetencyId
        }

        if (state.firmStaffRequestQualification.analyticalAbilityCompetencyLevel === null) {
          state.firmStaffRequestQualification.analyticalAbilityCompetencyLevelId = null
        } else if (typeof (state.firmStaffRequestQualification.analyticalAbilityCompetencyLevel) === 'object') {
          state.firmStaffRequestQualification.analyticalAbilityCompetencyLevelId = state.firmStaffRequestQualification.analyticalAbilityCompetencyLevel.technicalBasicCompetencyId
        }

        if (state.firmStaffRequestQualification.attentionAbilityCompetencyLevel === null) {
          state.firmStaffRequestQualification.attentionAbilityCompetencyLevelId = null
        } else if (typeof (state.firmStaffRequestQualification.attentionAbilityCompetencyLevel) === 'object') {
          state.firmStaffRequestQualification.attentionAbilityCompetencyLevelId = state.firmStaffRequestQualification.attentionAbilityCompetencyLevel.technicalBasicCompetencyId
        }

        if (state.firmStaffRequestQualification.learnabilityCompetencyLevel === null) {
          state.firmStaffRequestQualification.learnabilityCompetencyLevelId = null
        } else if (typeof (state.firmStaffRequestQualification.learnabilityCompetencyLevel) === 'object') {
          state.firmStaffRequestQualification.learnabilityCompetencyLevelId = state.firmStaffRequestQualification.learnabilityCompetencyLevel.technicalBasicCompetencyId
        }

        if (state.firmStaffRequestQualification.applyingAbilityCompetencyLevel === null) {
          state.firmStaffRequestQualification.applyingAbilityCompetencyLevelId = null
        } else if (typeof (state.firmStaffRequestQualification.applyingAbilityCompetencyLevel) === 'object') {
          state.firmStaffRequestQualification.applyingAbilityCompetencyLevelId = state.firmStaffRequestQualification.applyingAbilityCompetencyLevel.technicalBasicCompetencyId
        }

        if (state.firmStaffRequestQualification.innovationAbilityCompetencyLevel === null) {
          state.firmStaffRequestQualification.innovationAbilityCompetencyLevelId = null
        } else if (typeof (state.firmStaffRequestQualification.innovationAbilityCompetencyLevel) === 'object') {
          state.firmStaffRequestQualification.innovationAbilityCompetencyLevelId = state.firmStaffRequestQualification.innovationAbilityCompetencyLevel.technicalBasicCompetencyId
        }

        if (state.firmStaffRequestQualification.communicationAbilityCompetencyLevel === null) {
          state.firmStaffRequestQualification.communicationAbilityCompetencyLevelId = null
        } else if (typeof (state.firmStaffRequestQualification.communicationAbilityCompetencyLevel) === 'object') {
          state.firmStaffRequestQualification.communicationAbilityCompetencyLevelId = state.firmStaffRequestQualification.communicationAbilityCompetencyLevel.technicalBasicCompetencyId
        }

        if (state.firmStaffRequestQualification.solutionAbilityCompetencyLevel === null) {
          state.firmStaffRequestQualification.solutionAbilityCompetencyLevelId = null
        } else if (typeof (state.firmStaffRequestQualification.solutionAbilityCompetencyLevel) === 'object') {
          state.firmStaffRequestQualification.solutionAbilityCompetencyLevelId = state.firmStaffRequestQualification.solutionAbilityCompetencyLevel.technicalBasicCompetencyId
        }

        if (state.firmStaffRequestQualification.planningAbilityCompetencyLevel === null) {
          state.firmStaffRequestQualification.planningAbilityCompetencyLevelId = null
        } else if (typeof (state.firmStaffRequestQualification.planningAbilityCompetencyLevel) === 'object') {
          state.firmStaffRequestQualification.planningAbilityCompetencyLevelId = state.firmStaffRequestQualification.planningAbilityCompetencyLevel.technicalBasicCompetencyId
        }

        if (state.firmStaffRequestQualification.coordinationAbilityCompetencyLevel === null) {
          state.firmStaffRequestQualification.coordinationAbilityCompetencyLevelId = null
        } else if (typeof (state.firmStaffRequestQualification.coordinationAbilityCompetencyLevel) === 'object') {
          state.firmStaffRequestQualification.coordinationAbilityCompetencyLevelId = state.firmStaffRequestQualification.coordinationAbilityCompetencyLevel.technicalBasicCompetencyId
        }

        if (state.firmStaffRequestQualification.obeyingAbilityCompetencyLevel === null) {
          state.firmStaffRequestQualification.obeyingAbilityCompetencyLevelId = null
        } else if (typeof (state.firmStaffRequestQualification.obeyingAbilityCompetencyLevel) === 'object') {
          state.firmStaffRequestQualification.obeyingAbilityCompetencyLevelId = state.firmStaffRequestQualification.obeyingAbilityCompetencyLevel.technicalBasicCompetencyId
        }

        if (state.firmStaffRequestQualification.controlAuditingAbilityCompetencyLevel === null) {
          state.firmStaffRequestQualification.controlAuditingAbilityCompetencyLevelId = null
        } else if (typeof (state.firmStaffRequestQualification.controlAuditingAbilityCompetencyLevel) === 'object') {
          state.firmStaffRequestQualification.controlAuditingAbilityCompetencyLevelId = state.firmStaffRequestQualification.controlAuditingAbilityCompetencyLevel.technicalBasicCompetencyId
        }

        if (state.firmStaffRequestQualification.designAbilityCompetencyLevel === null) {
          state.firmStaffRequestQualification.designAbilityCompetencyLevelId = null
        } else if (typeof (state.firmStaffRequestQualification.designAbilityCompetencyLevel) === 'object') {
          state.firmStaffRequestQualification.designAbilityCompetencyLevelId = state.firmStaffRequestQualification.designAbilityCompetencyLevel.technicalBasicCompetencyId
        }

        if (state.firmStaffRequestQualification.representationAbilityCompetencyLevel === null) {
          state.firmStaffRequestQualification.representationAbilityCompetencyLevelId = null
        } else if (typeof (state.firmStaffRequestQualification.representationAbilityCompetencyLevel) === 'object') {
          state.firmStaffRequestQualification.representationAbilityCompetencyLevelId = state.firmStaffRequestQualification.representationAbilityCompetencyLevel.technicalBasicCompetencyId
        }

        if (state.firmStaffRequestQualification.educationImportanceLevel === null) {
          state.firmStaffRequestQualification.educationImportanceLevelId = null
        } else if (typeof (state.firmStaffRequestQualification.educationImportanceLevel) === 'object') {
          state.firmStaffRequestQualification.educationImportanceLevelId = state.firmStaffRequestQualification.educationImportanceLevel.importanceLevelId
        }

        if (state.firmStaffRequestQualification.vocationalHighSchoolImportanceLevel === null) {
          state.firmStaffRequestQualification.vocationalHighSchoolImportanceLevelId = null
        } else if (typeof (state.firmStaffRequestQualification.vocationalHighSchoolImportanceLevel) === 'object') {
          state.firmStaffRequestQualification.vocationalHighSchoolImportanceLevelId = state.firmStaffRequestQualification.vocationalHighSchoolImportanceLevel.importanceLevelId
        }

        if (state.firmStaffRequestQualification.vocationalCollegeImportanceLevel === null) {
          state.firmStaffRequestQualification.vocationalCollegeImportanceLevelId = null
        } else if (typeof (state.firmStaffRequestQualification.vocationalCollegeImportanceLevel) === 'object') {
          state.firmStaffRequestQualification.vocationalCollegeImportanceLevelId = state.firmStaffRequestQualification.vocationalCollegeImportanceLevel.importanceLevelId
        }

        if (state.firmStaffRequestQualification.universityImportanceLevel === null) {
          state.firmStaffRequestQualification.universityImportanceLevelId = null
        } else if (typeof (state.firmStaffRequestQualification.universityImportanceLevel) === 'object') {
          state.firmStaffRequestQualification.universityImportanceLevelId = state.firmStaffRequestQualification.universityImportanceLevel.importanceLevelId
        }

        if (state.firmStaffRequestQualification.postgraduateImportanceLevel === null) {
          state.firmStaffRequestQualification.postgraduateImportanceLevelId = null
        } else if (typeof (state.firmStaffRequestQualification.postgraduateImportanceLevel) === 'object') {
          state.firmStaffRequestQualification.postgraduateImportanceLevelId = state.firmStaffRequestQualification.postgraduateImportanceLevel.importanceLevelId
        }

        if (state.firmStaffRequestQualification.yearsOfExperience === null) {
          state.firmStaffRequestQualification.yearsOfExperienceId = null
        } else if (typeof (state.firmStaffRequestQualification.yearsOfExperience) === 'object') {
          state.firmStaffRequestQualification.yearsOfExperienceId = state.firmStaffRequestQualification.yearsOfExperience.experienceYearId
        }

        if (state.firmStaffRequestQualification.yearsOfExperienceImportanceLevel === null) {
          state.firmStaffRequestQualification.yearsOfExperienceImportanceLevelId = null
        } else if (typeof (state.firmStaffRequestQualification.yearsOfExperienceImportanceLevel) === 'object') {
          state.firmStaffRequestQualification.yearsOfExperienceImportanceLevelId = state.firmStaffRequestQualification.yearsOfExperienceImportanceLevel.importanceLevelId
        }

        if (state.firmStaffRequestQualification.educationLevel === null) {
          state.firmStaffRequestQualification.educationLevelId = null
        } else if (typeof (state.firmStaffRequestQualification.educationLevel) === 'object') {
          state.firmStaffRequestQualification.educationLevelId = state.firmStaffRequestQualification.educationLevel.educationLevelId
        }
        state.firmStaffRequestQualification.educationLevel = null
        state.firmStaffRequestQualification.educationImportanceLevel = null
        state.firmStaffRequestQualification.vocationalHighSchoolImportanceLevel = null
        state.firmStaffRequestQualification.vocationalCollegeImportanceLevel = null
        state.firmStaffRequestQualification.universityImportanceLevel = null
        state.firmStaffRequestQualification.postgraduateImportanceLevel = null
        state.firmStaffRequestQualification.yearsOfExperience = null
        state.firmStaffRequestQualification.yearsOfExperienceImportanceLevel = null
        state.firmStaffRequestQualification.numericalAbilityCompetencyLevel = null
        state.firmStaffRequestQualification.analyticalAbilityCompetencyLevel = null
        state.firmStaffRequestQualification.attentionAbilityCompetencyLevel = null
        state.firmStaffRequestQualification.learnabilityCompetencyLevel = null
        state.firmStaffRequestQualification.applyingAbilityCompetencyLevel = null
        state.firmStaffRequestQualification.innovationAbilityCompetencyLevel = null
        state.firmStaffRequestQualification.communicationAbilityCompetencyLevel = null
        state.firmStaffRequestQualification.solutionAbilityCompetencyLevel = null
        state.firmStaffRequestQualification.planningAbilityCompetencyLevel = null
        state.firmStaffRequestQualification.coordinationAbilityCompetencyLevel = null
        state.firmStaffRequestQualification.obeyingAbilityCompetencyLevel = null
        state.firmStaffRequestQualification.controlAuditingAbilityCompetencyLevel = null
        state.firmStaffRequestQualification.designAbilityCompetencyLevel = null
        state.firmStaffRequestQualification.representationAbilityCompetencyLevel = null

        firmReqQualificationApi.GetStaffQualificationByRequestId(state.firmStaffRequestQualification.staffRequestId).then(res => {
          if (res.data.entity == null) state.firmStaffRequestQualification.staffRequestQualificationId = 0
          else state.firmStaffRequestQualification.staffRequestQualificationId = res.data.entity.staffRequestQualificationId

          if (state.firmStaffRequestQualification.staffRequestQualificationId === 0) {
            firmReqQualificationApi.AddStaffQualification(state.firmStaffRequestQualification).then(resp => {
              result = resp.data.entity
              commit('SET_FIRMSTAFFREQUEST_QUALIFICATION', resp.data.entity)
              resolve(result)
            })
            return result
          }
          if (state.firmStaffRequestQualification.staffRequestQualificationId > 0) {
            firmReqQualificationApi.UpdateStaffQualification(state.firmStaffRequestQualification).then(resp => {
              result = resp.data.entity
              resolve(result)
            })
            return result
          }
          reject()
          return result
        })
      })
    },
  },
}
