// eslint-disable-next-line import/no-cycle
import request from '@/utils/request'

// eslint-disable-next-line import/prefer-default-export
export function login(data) {
  return request({
    url: '/api/Auth/SignIn',
    method: 'post',
    data,
  })
}

export function signup(data) {
  return request({
    url: '/api/Auth/SignUp',
    method: 'post',
    data,
  })
}

export function assignUserGroup(data) {
  return request({
    url: '/api/Auth/AssignUserGroup',
    method: 'post',
    data,
  })
}
// /api/Auth/SendActivationEMail/kadiryasar%40e-yaz.com.tr
export function SendActivationEMail(data) {
  return request({
    url: `/api/Auth/SendActivationEMail/${data}`,
    method: 'get',
  })
}
export function VerifyEMailAddress(data) {
  return request({
    url: `/api/Auth/VerifyEMailAddress/${data}`,
    method: 'get',
  })
}
