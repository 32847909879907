/* eslint-disable import/no-cycle */
import request from '@/utils/request'

export function GetApplicantEducations() {
  return request({
    url: '/api/ApplicantEducations/GetApplicantEducations',
    method: 'get',
  })
}

export function GetApplicantEducationById(data) {
  return request({
    url: `/api/ApplicantEducations/GetApplicantEducationById/${data}`,
    method: 'get',
  })
}

export function GetApplicantEducationsByApplicantId(data) {
  return request({
    url: `/api/ApplicantEducations/GetApplicantEducationsByApplicantId/${data}`,
    method: 'get',
  })
}

export function AddApplicantEducation(data) {
  return request({
    url: '/api/ApplicantEducations/AddApplicantEducation',
    method: 'post',
    data,
  })
}

export function UpdateApplicantEducation(data) {
  return request({
    url: '/api/ApplicantEducations/UpdateApplicantEducation',
    method: 'put',
    data,
  })
}

export function DeleteApplicantEducation(data) {
  return request({
    url: `/api/ApplicantEducations/DeleteApplicantEducation?applicantEducationId=${data}`,
    method: 'delete',
  })
}

/*
[HttpGet("GetApplicantEducations")]
[HttpGet("GetApplicantEducationById/{id}")]
[HttpGet("GetApplicantEducationsByApplicantId/{applicantId}")]
[HttpPost("AddApplicantEducation")]
[HttpPut("UpdateApplicantEducation")]
[HttpDelete("DeleteApplicantEducation")]
*/
