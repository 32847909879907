/* eslint-disable import/no-cycle */
import request from '@/utils/request'

export function GetBenefitsAll() {
  return request({
    url: '/api/FirmBenefits/GetBenefitsAll',
    method: 'get',
  })
}

export function GetBenefitById(data) {
  return request({
    url: `/api/FirmBenefits/GetBenefitById/${data}`,
    method: 'get',
  })
}

export function GetBenefitByFirmId(firmId) {
  return request({
    url: `/api/FirmBenefits/GetBenefitByFirmId/${firmId}`,
    method: 'get',
  })
}

export function AddBenefit(data) {
  return request({
    url: 'api/FirmBenefits/AddBenefit',
    method: 'post',
    data,
  })
}

export function UpdateBenefit(data) {
  return request({
    url: '/api/FirmBenefits/UpdateBenefit',
    method: 'put',
    data,
  })
}
export function DeleteBenefit(data) {
  return request({
    url: '/api/FirmBenefits/DeleteBenefit',
    method: 'delete',
    data,
  })
}
