/* eslint-disable import/no-cycle */
import * as apiApplicant from '@/api/islemler/applicant/applicantInfo'
import * as apiEdu from '@/api/islemler/applicant/applicantEducation'
import * as apiAbility from '@/api/islemler/applicant/applicantAbility'
import * as apiFunc from '@/api/islemler/applicant/applicantFunctions'
import * as apiExp from '@/api/islemler/applicant/applicantExperiences'

export default {
  namespaced: true,
  state:
  {
    applicant: {},
    defaultApplicant: {
      applicantId: 0,
      applicantName: '',
      applicantTCKN: '',
      applicantEmail: '',
      applicantPhone: '',
      city: '',
      town: '',
      district: '',
      street: '',
      doorNumber: '',
      addressDefinition: '',
      birthDate: '1900-01-01T00:00:00.000Z',
      birthCity: '',
      birthTown: '',
      gender: 0,
      maritalStatus: 0,
      militaryStatus: 0,
      endDateDelay: '1900-01-01T00:00:00.000Z',
      heightCm: 0,
      weightKg: 0,
      isDisabled: true,
      isSmoker: false,
      canTravel: false,
      hasWorkShifts: false,
      hasReferences: false,
      hasCriminalRecord: false,
      canDrive: false,
      driverLicenseType: null,
      driverLicenseTypeId: null,
      obstacleDiseases: '',
      photo: '',
      rowState: 1,
      applicantCompleted: 0,
      authUserId: 0,
      status: 0,
    },
    applicantEducation: {},
    defaultApplicantEducation:
      {
        applicantEId: 0,
        applicantId: 0,
        educationLevel: null,
        educationLevelId: null,
        vocationHighSchool: '',
        vocationHighSchoolCountry: '',
        vocationHighSchoolName: '',
        vocationHighSchoolYearGrad: 0,
        vocationHighSchoolPoint: 0,
        vocationCollege: '',
        vocationCollegeCountry: '',
        vocationCollegeName: '',
        vocationCollegeYearGrad: 0,
        vocationCollegePoint: 0,
        university: '',
        universityCountry: '',
        universityName: '',
        universityYearGrad: 0,
        universityPoint: 0,
        postgraduateCountry: '',
        postgraduateName: '',
        postgraduateYearGrad: 0,
        postgraduatePoint: 0,
      },
    applicantAbility: {},
    defaultApplicantAbility:
    {
      applicantAbilityId: 0,
      applicantId: 0,
      applicant: [],

      numericalAbility: null,
      numericalAbilityId: null,
      numericalPoint: 0,

      analyticalThinkingAbility: null,
      analyticalThinkingAbilityId: null,
      analyticalThinkingPoint: 0,

      learnabilityAbility: null,
      learnabilityAbilityId: null,
      learnabilityPoint: 0,

      innovationAbility: null,
      innovationAbilityId: null,
      innovationPoint: 0,

      communicationAbility: null,
      communicationAbilityId: null,
      communicationPoint: 0,

      solutionAbility: null,
      solutionAbilityId: null,
      solutionPoint: 0,

      applyingAbility: null,
      applyingAbilityId: null,
      applyingAbilityPoint: 0,

      attentionAbility: null,
      attentionAbilityId: null,
      attentionPoint: 0,

      planningAbility: null,
      planningAbilityId: null,
      planningPoint: 0,

      coordinationAbility: null,
      coordinationAbilityId: null,
      coordinationPoint: 0,

      obeyingAbility: null,
      obeyingAbilityId: null,
      obeyingPoint: 0,

      designAbility: null,
      designAbilityId: null,
      designPoint: 0,

      representationAbility: null,
      representationAbilityId: null,
      representationPoint: 0,

      controllingAbility: null,
      controllingAbilityId: null,
      controllingPoint: 0,

      notesOfAdmin: '',
      expertsOpinion: '',
    },
    applicantFunctions: {},
    defaultApplicantFunctions: {
      applicantFUId: 0,
      applicantId: 0,
      q1: 0,
      q2: 0,
      q3: 0,
      q4: 0,
      q5: 0,
      q6: 0,
      financialRiskLevel: null,
      financialRiskLevelId: null,
      financialRiskNewJob: false,

      legalRiskLevel: null,
      legalRiskLevelId: null,
      legalRiskNewJob: false,

      medicalRiskLevel: null,
      medicalRiskLevelId: null,
      medicalRiskNewJob: false,

      lifeRiskLevel: null,
      lifeRiskLevelId: null,
      lifeRiskNewJob: false,

      salaryMax: 0,
      salaryMin: 0,
      performancePayment: true,
      socialBonusPayment: true,
      socialChildBenefit: true,
      socialHeatingAid: true,
      socialEducationalAid: true,
      socialIndividualPension: true,
      socialLifeInsurance: true,
      socialUnavailable: true,
      socialOther: true,
      socialOtherDescription: '',
    },
    applicantExperiences: {},
    defaultApplicantExperiences:
    {
      applicantEXId: 0,
      applicantId: 0,
      position1: '',
      position1Factor: '',
      position2: '',
      position2Factor: '',
      position3: '',
      position3Factor: '',
      position4: '',
      position4Factor: '',
      position5: '',
      position5Factor: '',
      experienceYear: 0,
      experienceYearFactor: null,
    },

    applicantPictureB64: '',
    defaultAdayId: -1,
    adayId: -1,
  },
  getters: {
    GET_APPLICANT: state => state.applicant,
    GET_APPLICANTEDUCATION: state => state.applicantEducation,
    GET_APPLICANTABILITIES: state => state.applicantAbility,
    GET_APPLICANTFUNCTIONS: state => state.applicantFunctions,
    GET_APPLICANTEXPERIENCES: state => state.applicantExperiences,
    GET_APPLICANTPICTURE: state => state.applicantPictureB64,
    GET_ADAYID: state => state.adayId,
  },
  mutations: {
    SET_APPLICANT: (state, applicant) => {
      state.applicant = applicant
    },
    SET_APPLICANTEDUCATION: (state, applicantEducation) => {
      state.applicantEducation = applicantEducation
    },
    SET_APPLICANTABILITIES: (state, applicantAbility) => {
      state.applicantAbility = applicantAbility
    },
    SET_APPLICANTFUNCTIONS: (state, applicantFunctions) => {
      state.applicantFunctions = applicantFunctions
    },
    SET_APPLICANTEXPERIENCES: (state, applicantExperiences) => {
      state.applicantExperiences = applicantExperiences
    },
    SET_ADAYID: (state, adayId) => {
      state.adayId = adayId
    },

    RESET_APPLICANT: state => {
      state.applicant = { ...state.defaultApplicant }
    },
    RESET_APPLICANTEDUCATION: state => {
      state.applicantEducation = { ...state.defaultApplicantEducation }
    },
    RESET_APPLICANTABILITIES: state => {
      state.applicantAbility = { ...state.defaultApplicantAbility }
    },
    RESET_APPLICANTFUNCTIONS: state => {
      state.applicantFunctions = { ...state.defaultApplicantFunctions }
    },
    RESET_APPLICANTEXPERIENCES: state => {
      state.applicantExperiences = { ...state.defaultApplicantExperiences }
    },
    RESET_ADAYID: state => {
      state.adayId = -1 // { ...state.defaultAdayId }
    },
  },
  actions: {
    action_get_applicant({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        commit('RESET_APPLICANT')
        if (payload === -1) {
          commit('SET_APPLICANT', state.defaultApplicant)
          resolve(true)
        }
        apiApplicant.GetApplicantById(payload).then(res => {
          if (res.data.entity === null) {
            commit('SET_APPLICANT', state.defaultApplicant)
          } else {
            const applcnt = res.data.entity
            state.adayId = applcnt.applicantId
            if (res.data.entity.obstacleDiseases !== null) {
              const arr = res.data.entity.obstacleDiseases.split(',')
              applcnt.obstacleDiseases = arr
            }
            commit('SET_APPLICANT', applcnt)
          }
          resolve(true)
        }).catch(error => {
          commit('SET_APPLICANT', {})
          reject(error)
        })
      })
    },
    action_save_applicant({ commit, state }) {
      return new Promise((resolve, reject) => {
        let result = -1

        if (state.applicant.driverLicenseType != null) {
          state.applicant.driverLicenseTypeId = state.applicant.driverLicenseType.driverLicenceTypeId
          state.applicant.driverLicenseType = null
        }

        if (state.applicant.obstacleDiseases.length > 0) {
          const arr = state.applicant.obstacleDiseases
          state.applicant.obstacleDiseases = arr.join(',')
        }
        if (state.applicant.applicantId === 0) {
          if (state.applicant.birthDate === '') {
            const d = new Date()
            state.applicant.birthDate = d.toISOString()
          }
          if (state.applicant.endDateDelay === '') {
            const d = new Date()
            state.applicant.endDateDelay = d.toISOString()
          }
          const rd = new Date()
          state.applicant.registrationDate = rd.toISOString()

          const d = new Date()
          state.applicant.updatedDate = d.toISOString()
          apiApplicant.AddApplicant(state.applicant).then(res => {
            result = res.data.entity
            resolve(result)
            commit('SET_APPLICANT', state.applicant)
            return result
          })
        }
        if (state.applicant.applicantId > 0) {
          if (state.applicant.obstacleDiseases.length === 0) {
            state.applicant.obstacleDiseases = ''
          }
          const d = new Date()
          state.applicant.updatedDate = d.toISOString()
          apiApplicant.UpdateApplicant(state.applicant).then(res => {
            result = res.data.entity
            resolve(result)
          }).catch(error => {
            reject(error)
          })
          return result
        }
        return result
      })
    },
    action_get_applicantEducation({ commit, state }, payload) {
      commit('RESET_APPLICANTEDUCATION')
      return new Promise((resolve, reject) => {
        if (payload === -1) {
          commit('SET_APPLICANTEDUCATION', state.defaultApplicantEducation)
          resolve(true)
          return
        }
        apiEdu.GetApplicantEducationsByApplicantId(payload).then(res => {
          if (res.data.entity === null) {
            commit('SET_APPLICANTEDUCATION', state.defaultApplicantEducation)
          } else {
            commit('SET_APPLICANTEDUCATION', res.data.entity)
          }
          resolve(true)
        }).catch(error => {
          commit('SET_APPLICANTEDUCATION', {})
          reject(error)
        })
      })
    },
    action_save_applicantEducation({ state }, applicantId) {
      return new Promise((resolve, reject) => {
        let result = -1
        apiEdu.GetApplicantEducationsByApplicantId(applicantId).then(resp => {
          if (resp.data.entity == null) state.applicantEducation.applicantEId = 0
          else state.applicantEducation.applicantEId = resp.data.entity.applicantEId

          if (state.applicantEducation.applicantEId === 0 && applicantId > 0) {
            state.applicantEducation.applicantId = applicantId
            apiEdu.AddApplicantEducation(state.applicantEducation).then(res => {
              result = res.data.entity
              resolve(result)
            })
            return result
          }
          if (state.applicantEducation.applicantEId > 0) {
            state.applicantEducation.educationLevel = null
            apiEdu.UpdateApplicantEducation(state.applicantEducation).then(res => {
              result = res.data.entity ? -1 : 0
              resolve(result)
            })
            return result
          }
          reject()
          return result
        })
      })
    },
    action_get_applicantAbilities({ commit, state }, payload) {
      commit('RESET_APPLICANTABILITIES')
      return new Promise((resolve, reject) => {
        if (payload === -1) {
          commit('SET_APPLICANTABILITIES', state.defaultApplicantAbility)
          resolve(true)
          return
        }
        apiAbility.GetApplicantAbilityByApplicantId(payload).then(res => {
          if (res.data.entity === null) {
            commit('SET_APPLICANTABILITIES', state.defaultApplicantAbility)
          } else {
            commit('SET_APPLICANTABILITIES', res.data.entity)
          }
          resolve(true)
        }).catch(error => {
          commit('SET_APPLICANTABILITIES', {})
          reject(error)
        })
      })
    },
    action_save_applicantAbilities({ state }, applicantId) {
      return new Promise((resolve, reject) => {
        let result = -1
        state.applicantAbility.numericalAbility = null
        state.applicantAbility.analyticalThinkingAbility = null
        state.applicantAbility.learnabilityAbility = null
        state.applicantAbility.innovationAbility = null
        state.applicantAbility.communicationAbility = null
        state.applicantAbility.solutionAbility = null
        state.applicantAbility.applyingAbility = null
        state.applicantAbility.attentionAbility = null
        state.applicantAbility.planningAbility = null
        state.applicantAbility.coordinationAbility = null
        state.applicantAbility.obeyingAbility = null
        state.applicantAbility.designAbility = null
        state.applicantAbility.representationAbility = null
        state.applicantAbility.controllingAbility = null

        apiAbility.GetApplicantAbilityByApplicantId(applicantId).then(rep => {
          if (rep.data.entity === null) {
            state.applicantAbility.applicantAbilityId = 0
          } else {
            state.applicantAbility.applicantAbilityId = rep.data.entity.applicantAbilityId
          }

          if (state.applicantAbility.applicantAbilityId === 0 && applicantId > 0) {
            state.applicantAbility.applicantId = applicantId
            apiAbility.AddApplicantAbility(state.applicantAbility).then(res => {
              result = res.data.entity !== 0 ? res.data.entity : 0
              resolve(result)
            })
            return result
          }
          if (state.applicantAbility.applicantAbilityId > 0) {
            apiAbility.UpdateApplicantAbility(state.applicantAbility).then(res => {
              result = res.data.entity !== 0 ? res.data.entity : 0
              resolve(result)
            })
            return result
          }
          reject()
          return result
        })
      })
    },
    action_get_applicantFunctions({ commit, state }, payload) {
      commit('RESET_APPLICANTFUNCTIONS')
      return new Promise((resolve, reject) => {
        if (payload === -1) {
          commit('SET_APPLICANTFUNCTIONS', state.defaultApplicantFunctions)
          resolve(true)
          return
        }
        apiFunc.GetApplicantFunctionByApplicantId(payload).then(res => {
          if (res.data.entity == null) {
            commit('SET_APPLICANTFUNCTIONS', state.defaultApplicantFunctions)
          } else {
            commit('SET_APPLICANTFUNCTIONS', res.data.entity)
          }
          resolve(true)
        }).catch(error => {
          commit('SET_APPLICANTFUNCTIONS', {})
          reject(error)
        })
      })
    },
    action_save_applicantFunctions({ state }, applicantId) {
      return new Promise((resolve, reject) => {
        let result = -1
        apiFunc.GetApplicantFunctionByApplicantId(applicantId).then(resp => {
          if (resp.data.entity == null) state.applicantFunctions.applicantFUId = 0
          else state.applicantFunctions.applicantFUId = resp.data.entity.applicantFUId
          if (state.applicantFunctions.applicantFUId === 0 && applicantId > 0) {
            state.applicantFunctions.applicantId = applicantId
            state.applicantFunctions.applicant = state.applicant
            apiFunc.AddApplicantFunction(state.applicantFunctions).then(res => {
              result = res.data.entity
              resolve(result)
            })
            return result
          }
          if (state.applicantFunctions.applicantFUId > 0) {
            apiFunc.UpdateApplicantFunction(state.applicantFunctions).then(res => {
              result = res.data.entity ? -1 : 0
              resolve(result)
            })
            return result
          }
          reject()
          return result
        })
      })
    },
    action_get_applicantExperiences({ commit, state }, payload) {
      commit('RESET_APPLICANTEXPERIENCES')
      return new Promise((resolve, reject) => {
        if (payload === -1) {
          commit('SET_APPLICANTEXPERIENCES', state.defaultApplicantExperiences)
          resolve(true)
          return
        }
        apiExp.GetApplicantExperienceByApplicantId(payload).then(res => {
          if (res.data.entity === null) {
            commit('SET_APPLICANTEXPERIENCES', state.defaultApplicantExperiences)
          } else {
            commit('SET_APPLICANTEXPERIENCES', res.data.entity)
          }
          resolve(true)
        }).catch(error => {
          commit('SET_APPLICANTEXPERIENCES', {})
          reject(error)
        })
      })
    },
    action_save_applicantExperiences({ state }, applicantId) {
      return new Promise((resolve, reject) => {
        state.applicantExperiences.experienceYearFactor = null
        let result = -1
        apiExp.GetApplicantExperienceByApplicantId(applicantId).then(resp => {
          if (resp.data.entity == null) state.applicantExperiences.applicantEXId = 0
          else state.applicantExperiences.applicantEXId = resp.data.entity.applicantEXId
          if (state.applicantExperiences.applicantEXId === 0 && applicantId > 0) {
            state.applicantExperiences.applicantId = applicantId
            apiExp.AddApplicantExperience(state.applicantExperiences).then(res => {
              result = res.data.entity
              resolve(result)
            })
            return result
          }
          if (state.applicantExperiences.applicantEXId > 0) {
            state.applicantExperiences.position1Factor = state.applicantExperiences.position1Factor === null ? '' : state.applicantExperiences.position1Factor
            state.applicantExperiences.position2Factor = state.applicantExperiences.position2Factor === null ? '' : state.applicantExperiences.position2Factor
            state.applicantExperiences.position3Factor = state.applicantExperiences.position3Factor === null ? '' : state.applicantExperiences.position3Factor
            state.applicantExperiences.position4Factor = state.applicantExperiences.position4Factor === null ? '' : state.applicantExperiences.position4Factor
            state.applicantExperiences.position5Factor = state.applicantExperiences.position5Factor === null ? '' : state.applicantExperiences.position5Factor
            apiExp.UpdateApplicantExperience(state.applicantExperiences).then(res => {
              result = res.data.entity ? -1 : 0
              resolve(result)
            })
            return result
          }
          reject()
          return result
        })
      })
    },

    action_reset_applicant({ commit }) {
      commit('RESET_APPLICANT')
      commit('RESET_APPLICANTEDUCATION')
      commit('RESET_APPLICANTABILITIES')
      commit('RESET_APPLICANTFUNCTIONS')
      commit('RESET_APPLICANTEXPERIENCES')
    },
    action_set_applicant_picture({ state }, logo) {
      return new Promise(resolve => {
        state.applicantPictureB64 = logo
        resolve(logo)
      })
    },
  },
}
