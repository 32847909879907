/* eslint-disable import/no-cycle */
import request from '@/utils/request'

export function GetStaffRequestQualifications() {
  return request({
    url: '/api/StaffRequestQualifications/GetStaffQualifications',
    method: 'GET',
  })
}

export function GetStaffQualificationById(data) {
  return request({
    url: `/api/StaffRequestQualifications/GetStaffQualificationById/${data}`,
    method: 'GET',
  })
}

export function GetStaffQualificationByRequestId(data) {
  return request({
    url: `/api/StaffRequestQualifications/GetStaffQualificationByRequestId/${data}`,
    method: 'GET',
  })
}

export function AddStaffQualification(data) {
  return request({
    url: '/api/StaffRequestQualifications/AddStaffQualification',
    method: 'POST',
    data,
  })
}

export function UpdateStaffQualification(data) {
  return request({
    url: '/api/StaffRequestQualifications/UpdateStaffQualification',
    method: 'PUT',
    data,
  })
}

export function DeleteStaffQualification(data) {
  return request({
    url: `/api/StaffRequestQualifications/DeleteStaffQualification/${data}`,
    method: 'DELETE',
  })
}
