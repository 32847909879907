/* eslint-disable import/no-cycle */
import request from '@/utils/request'

export function GetStaffRequestSectorExperiences() {
  return request({
    url: '/api/StaffRequestSectorExperiences/GetStaffRequestSectorExperiences',
    method: 'GET',
  })
}

export function GetStaffRequestSectorExperienceById(data) {
  return request({
    url: `/api/StaffRequestSectorExperiences/GetStaffRequestSectorExperienceById/${data}`,
    method: 'GET',
  })
}

export function GetStaffRequestSectorExperienceByRequestId(data) {
  return request({
    url: `/api/StaffRequestSectorExperiences/GetStaffRequestSectorExperienceByRequestId/${data}`,
    method: 'GET',
  })
}

export function AddStaffRequestSectorExperience(data) {
  return request({
    url: '/api/StaffRequestSectorExperiences/AddStaffRequestSectorExperience',
    method: 'POST',
    data,
  })
}

export function UpdateStaffRequestSectorExperience(data) {
  return request({
    url: '/api/StaffRequestSectorExperiences/UpdateStaffRequestSectorExperience',
    method: 'PUT',
    data,
  })
}

export function DeleteStaffRequestSectorExperience(data) {
  return request({
    url: `/api/StaffRequestSectorExperiences/DeleteStaffRequestSectorExperience/${data}`,
    method: 'DELETE',
  })
}
