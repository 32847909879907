/* eslint-disable import/no-cycle */
import request from '@/utils/request'

export function GetStaffRequestForeignLanuages() {
  return request({
    url: '/api/StaffRequestForeignLanguages/GetStaffRequestForeignLanguages',
    method: 'GET',
  })
}

export function GetStaffRequestForeignLanuageById(data) {
  return request({
    url: `/api/StaffRequestForeignLanguages/GetStaffRequestForeignLanguageById/${data}`,
    method: 'GET',
  })
}

export function GetStaffRequestForeignLanguagesByRequestId(data) {
  return request({
    url: `/api/StaffRequestForeignLanguages/GetStaffRequestForeignLanguageByRequestId/${data}`,
    method: 'GET',
  })
}

export function AddStaffRequestForeignLanguage(data) {
  return request({
    url: '/api/StaffRequestForeignLanguages/AddStaffRequestForeignLanguage',
    method: 'POST',
    data,
  })
}

export function UpdateStaffRequestForeignLanguage(data) {
  return request({
    url: '/api/StaffRequestForeignLanguages/UpdateStaffRequestForeignLanguage',
    method: 'PUT',
    data,
  })
}

export function DeleteStaffRequestForeignLanguage(data) {
  return request({
    url: `/api/StaffRequestForeignLanguages/DeleteStaffRequestForeignLanguage/${data}`,
    method: 'DELETE',
  })
}
