/* eslint-disable import/no-cycle */
import request from '@/utils/request'

export function GetStaffRequestComputers() {
  return request({
    url: '/api/StaffRequestComputers/GetStaffRequestComputers',
    method: 'GET',
  })
}

export function GetStaffRequestComputerById(data) {
  return request({
    url: `/api/StaffRequestComputers/GetStaffRequestComputerById/${data}`,
    method: 'GET',
  })
}

export function GetStaffRequestComputerByRequestId(data) {
  return request({
    url: `/api/StaffRequestComputers/GetStaffRequestComputerByRequestId/${data}`,
    method: 'GET',
  })
}

export function AddStaffRequestComputer(data) {
  return request({
    url: '/api/StaffRequestComputers/AddStaffRequestComputer',
    method: 'POST',
    data,
  })
}

export function UpdateStaffRequestComputer(data) {
  return request({
    url: '/api/StaffRequestComputers/UpdateStaffRequestComputer',
    method: 'PUT',
    data,
  })
}

export function DeleteStaffRequestComputer(data) {
  return request({
    url: `/api/StaffRequestComputers/DeleteStaffRequestComputer/${data}`,
    method: 'DELETE',
  })
}
