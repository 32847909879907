/* eslint-disable import/no-cycle */
import request from '@/utils/request'

export function GetApplicantAbilities() {
  return request({
    url: '/api/ApplicantAbilities/GetApplicantAbilities',
    method: 'get',
  })
}

export function GetApplicantAbilityById(data) {
  return request({
    url: `/api/ApplicantAbilities/GetApplicantAbilities/${data}`,
    method: 'get',
  })
}

export function GetApplicantAbilityByApplicantId(data) {
  return request({
    url: `/api/ApplicantAbilities/GetApplicantAbilityByApplicantId/${data}`,
    method: 'get',
  })
}

export function AddApplicantAbility(data) {
  return request({
    url: '/api/ApplicantAbilities/AddApplicantAbility',
    method: 'post',
    data,
  })
}

export function UpdateApplicantAbility(data) {
  return request({
    url: '/api/ApplicantAbilities/UpdateApplicantAbility',
    method: 'put',
    data,
  })
}

export function DeleteApplicantAbility(data) {
  return request({
    url: `/api/ApplicantAbilities/DeleteApplicantAbility?applicantInfoId=${data}`,
    method: 'delete',
  })
}
/*
[HttpGet("GetApplicantAbilities")]
[HttpGet("GetApplicantAbilityById/{id}")]
[HttpGet("GetApplicantAbilityByApplicantId/{applicantId}")]
[HttpPost("AddApplicantAbility")]
[HttpPut("UpdateApplicantAbility")]
[HttpDelete("DeleteApplicantAbility")]
*/
