/* eslint-disable import/no-cycle */
import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

import auth from './auth/auth'
import ulke from './tanimlar/ulke'
import sehir from './tanimlar/sehir'

import firmInventory from './islemler/firmInventory'
import staffRequest from './islemler/staffRequest'
import applicantInfo from './islemler/applicantInfo'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
    ulke,
    sehir,
    firmInventory,
    staffRequest,
    applicantInfo,
  },
  strict: process.env.DEV,
})
