/* eslint-disable import/no-cycle */
import * as ulke from '@/api/tanimlar/ulke'

export default {
  namespaced: true,
  state: {
    country: { id: 0, name: '' },
    list: [],
    page: 1,
  },
  getters: {
    GET_COUNTRYLIST: state => state.list,
    GET_PAGE: state => state.page,
    GET_COUNTRY: state => state.country,
  },
  mutations: {
    SET_COUNTRYLIST: (state, list) => {
      state.list = list
    },
    SET_COUNTRY: (state, country) => {
      state.country = country
    },
    PUSH_LIST: (state, data) => {
      state.list.push(state.list, data)
    },
    INCREASE_PAGE: state => {
      state.page += 1
    },
    SET_PAGE: (state, page) => {
      state.page = page
    },
  },
  actions: {
    action_get_countries({ commit }) {
      return new Promise((resolve, reject) => {
        ulke.getCountries().then(res => {
          commit('SET_COUNTRYLIST', res.data.list)
          resolve(true)
        }).catch(error => {
          reject(error)
        })
      })
    },
    action_get_country({ commit }, data) {
      return new Promise((resolve, reject) => {
        ulke.getCountryById(data).then(res => {
          if (res.data.entity === null) {
            reject(res.exceptionMessage)
          }
          const country = { id: res.data.entity.countryId, name: res.data.entity.countryName }
          commit('Country', country)

          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },
  },
}
