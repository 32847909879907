/* eslint-disable import/no-cycle */
import request from '@/utils/request'

// eslint-disable-next-line import/prefer-default-export
export function getCities() {
  return request({
    url: '/api/City/GetCitiesAll/',
    method: 'get',
  })
}

export function getCityById(data) {
  return request({
    url: `/api/City/GetCityById/${data}`,
    method: 'get',
  })
}

export function getCityByCountryId(data) {
  return request({
    url: `/api/City/GetCityByCountryId/${data}`,
    method: 'get',
  })
}

export function getCityByCityName(data) {
  return request({
    url: `/api/City/GetCityByCityName/${data}`,
    method: 'get',
  })
}

export function addCity(data) {
  return request({
    url: '/api/City/AddCity',
    method: 'post',
    data,
  })
}

export function updateCity(data) {
  return request({
    url: '/api/City/UpdateCity',
    method: 'put',
    data,
  })
}

export function deleteCity(data) {
  return request({
    url: `/api/City/DeleteCity?CityId=${data}`,
    method: 'delete',
  })
}
