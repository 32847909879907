/* eslint-disable import/no-cycle */
import request from '@/utils/request'

export function GetEmployementInfoAll() {
  return request({
    url: '​​/api​/FirmEmployementInfo​/GetEmployementInfoAll',
    method: 'get',
  })
}
export function GetEmployementInfoId(data) {
  return request({
    url: `/api/FirmEmployementInfo/GetEmployementInfoById/${data}`,
    method: 'get',
  })
}

export function GetEmployementInfoByFirmId(data) {
  return request({
    url: `/api/FirmEmployementInfo/GetEmployementInfoByFirmId/${data}`,
    method: 'get',
  })
}

export function AddEmployementInfo(data) {
  return request({
    url: '/api/FirmEmployementInfo/AddEmployementInfo',
    method: 'post',
    data,
  })
}

export function UpdateEmployementInfo(data) {
  return request({
    url: '/api/FirmEmployementInfo/UpdateEmployementInfo',
    method: 'put',
    data,
  })
}

export function DeleteEmployementInfo(data) {
  return request({
    url: '/api​/FirmEmployementInfo​/DeleteEmployementInfo',
    method: 'delete',
    data,
  })
}
