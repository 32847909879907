// eslint-disable-next-line import/no-cycle
import * as loginApi from '@/api/auth'
import * as authCookies from '@/utils/auth'

const UserGroups = {
  Administrators: 1,
  Users: 2,
  Employers: 3,
  Applicants: 4,
}

export { UserGroups }

export default {
  namespaced: true,
  state: {
    token: authCookies.getToken(),
    userName: authCookies.getUserName(),
    firstName: authCookies.getFirstName(),
    lastName: authCookies.getLastName(),
    roleName: authCookies.getRoleName(),
    authenticated: false,
  },
  getters: {
    GET_USERNAME: state => state.userName,
    GET_FIRSTNAME: state => state.firstName,
    GET_LASTNAME: state => state.lastName,
    GET_TOKEN: state => state.token,
    GET_ROLENAME: state => state.roleName,
    GET_AUTHSTATE: state => {
      const { token } = state
      state.authenticated = (token != null && token.length > 0)
      return state.authenticated
    },
  },
  mutations: {
    SET_USERNAME: (state, name) => {
      state.userName = name
      authCookies.setUserName(state.userName)
    },
    SET_FIRSTNAME: (state, name) => {
      state.firstName = name
      authCookies.setFirstName(state.firstName)
    },
    SET_LASTNAME: (state, name) => {
      state.lastName = name
      authCookies.setLastName(state.lastName)
    },
    SET_TOKEN: (state, token) => {
      state.token = token
      authCookies.setToken(state.token)
    },
    SET_ROLENAME: (state, roleName) => {
      state.roleName = roleName
      authCookies.setRoleName(state.roleName)
    },
  },
  actions: {
    action_login({ commit }, data) {
      return new Promise((resolve, reject) => {
        loginApi.login(data).then(res => {
          if (res.data.entity === null) {
            reject(res.data.exceptionMessage)
            return
          }

          const tokenType = 'Bearer'
          const token = (`${tokenType} ${res.data.entity.token}`)
          commit('SET_FIRSTNAME', res.data.entity.firstName)
          commit('SET_LASTNAME', res.data.entity.lastName)
          commit('SET_USERNAME', res.data.entity.userName)
          commit('SET_ROLENAME', res.data.entity.groups[0]?.authGroupName)
          commit('SET_TOKEN', token)
          resolve(true)
        }).catch(error => {
          reject(error)
        })
      })
    },
    action_logout({ commit }) {
      commit('SET_FIRSTNAME', null)
      commit('SET_LASTNAME', null)
      commit('SET_USERNAME', null)
      commit('SET_ROLENAME', null)
      commit('SET_TOKEN', null)

      authCookies.removeUserCookies()
    },
  },
}
