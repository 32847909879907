/* eslint-disable import/no-cycle */
import request from '@/utils/request'

export function GetEmployementPolicyAll() {
  return request({
    url: '/api/FirmEmployementPolicy/GetEmployementPolicyAll',
    method: 'get',
  })
}

export function GetEmployementPolicyByFirmId(data) {
  return request({
    url: `/api/FirmEmployementPolicy/GetEmployementPolicyByFirmId/${data}`,
    method: 'get',
  })
}

export function AddEmployementPolicy(data) {
  return request({
    url: '/api/FirmEmployementPolicy/AddEmployementPolicy',
    method: 'post',
    data,
  })
}

export function UpdateEmployementPolicy(data) {
  return request({
    url: '/api/FirmEmployementPolicy/UpdateEmployementPolicy',
    method: 'put',
    data,
  })
}
export function DeleteEmployementPolicy(data) {
  return request({
    url: '/api/FirmEmployementPolicy/DeleteEmployementPolicy',
    method: 'delete',
    data,
  })
}
