import Cookies from 'js-cookie'

const TokenKey = 'User-Token'
const UserNameKey = 'User-Name'
const FirstNameKey = 'First-Name'
const LastNameKey = 'Last-Name'
const RoleNameKey = 'Role-Name'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token, 1)
}

export function removeUserCookies() {
  Cookies.remove(UserNameKey)
  Cookies.remove(FirstNameKey)
  Cookies.remove(LastNameKey)
  Cookies.remove(RoleNameKey)
  Cookies.remove(TokenKey)
}

export function getUserName() {
  return Cookies.get(UserNameKey)
}

export function setUserName(name) {
  return Cookies.set(UserNameKey, name, 1)
}

export function getFirstName() {
  return Cookies.get(FirstNameKey)
}

export function setFirstName(name) {
  return Cookies.set(FirstNameKey, name, 1)
}

export function getLastName() {
  return Cookies.get(LastNameKey)
}

export function setLastName(name) {
  return Cookies.set(LastNameKey, name, 1)
}

export function getRoleName() {
  return Cookies.get(RoleNameKey)
}

export function setRoleName(name) {
  return Cookies.set(RoleNameKey, name, 1)
}

export function parseJwt(token) {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(c => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''))
  return JSON.parse(jsonPayload)
}

export function getUserObject() {
  const token = getToken()
  const user = parseJwt(token)
  return user
}
