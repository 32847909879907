/* eslint-disable import/no-cycle */
import * as firmInfoApi from '@/api/islemler/firminventory'
import * as firmBenefitApi from '@/api/islemler/FirmBenefits'
import * as firmContactInfoApi from '@/api/islemler/firmContactInfo'
import * as firmEmpInfApi from '@/api/islemler/FirmEmployementInfos'
import * as firmEmpPolApi from '@/api/islemler/FirmEmployementPolicy'

export default {
  namespaced: true,
  state: {
    firmInfo: {},
    defaultFirmInfo: {
      firmInfoId: 0,
      firmTitle: '',
      firmType: '',
      firmSectors: [],
      firmActivityArea: '',
      firmWebSite: '',
      firmTelephone1: '',
      firmTelephone2: '',
      firmEmail: '',
      firmCustomerGroupDomestic: 0,
      firmCustomerGroupAbroad: 0,
      firmCountOfStaffWhiteCollar: 0,
      firmCountOfStaffBlueCollar: 0,
      firmCountOfBranchInProvince: 0,
      firmCountOfTotalBranch: 0,
      firmTransportationService: '',
      firmCateringService: '',
      firmWeekdayMorningWorkingHoursStart: '',
      firmWeekdayMorningWorkingHoursEnd: '',
      firmWeekdayLunchBreakStart: '',
      firmWeekdayLunchBreakEnd: '',
      firmWeekdayOvertimeStart: '',
      firmWeekdayOvertimeEnd: '',
      firmWeekdayOvertimeBreakStart: '',
      firmWeekDayOvertimeBreakEnd: '',
      firmWeekendMorningWorkingHoursStart: '',
      firmWeekendMorningWorkingHoursEnd: '',
      firmWeekendLunchBreakStart: '',
      firmWeekendLunchBreakEnd: '',
      firmWeekendOvertimeStart: '',
      firmWeekendOvertimeEnd: '',
      firmWeekendOvertimeBreakStart: '',
      firmWeekendOvertimeBreakEnd: '',
      firmLogo: '',
      registerDate: '',
      authUserId: 0,
      rowState: 1,
    },
    firmBenefits: {},
    defaultFirmBenefit: {
      firmBenefitId: 0,
      firmInfoId: 0,
      transportationVehicle: true,
      transportationSurcharge: true,
      transportationVehicleOrSurcharge: true,
      transportationDependsOnContract: true,
      transportationUnavailable: true,
      mealProvidedInCompany: true,
      mealByTicket: true,
      mealInCompanyOrTicket: true,
      mealSurcharge: true,
      mealUnavailable: true,
      socialBonusPayment: false,
      socialChildBenefit: false,
      socialHeatingAid: false,
      socialEducationalAid: false,
      socialIndividualPension: false,
      socialLifeInsurance: false,
      socialUnavailable: false,
      socialOther: false,
      socialOtherDescription: '',
      rowState: 1,
    },
    firmContactInfo: {},
    defaultFirmContactInfo: {
      firmContactInfoId: 0,
      firmInfoId: 0,
      phoneNumber: '',
      phoneNumbe2: '',
      authorizedPerson: '',
      authorizedPersonTask: '',
      authorizedPersonPhoneNumber: '',
      authorizedPersonEMail: '',
      relatedPerson: '',
      relatedPersonTask: '',
      relatedPersonPhoneNumber: '',
      relatedPersonEMail: '',
      responsiblePerson1: '',
      responsiblePerson1Task: '',
      responsiblePerson1PhoneNumber: '',
      responsiblePerson1EMail: '',
      responsiblePerson2: '',
      responsiblePerson2Task: '',
      responsiblePerson2PhoneNumber: '',
      responsiblePerson2EMail: '',
      isCentralOffice: true,
      rowState: 1,
    },
    firmEmployementInfo: {},
    defaultFirmEmployementInfo: {
      FirmEmployementInfoId: 0,
      FirmInfoId: 0,
      WorkerStaffCount: 0,
      WorkerStaffCycleRate: 0,
      WorkerFindingDifficultyId: null,
      WorkerStaffingMethodId: null,
      WorkerDisagreementReasonId: null,
      WorkerQualificationExpectation: '',
      FunctionalSpecialistCount: 0,
      FunctionalSpecialistCycleRate: 0,
      FunctionalSpecialistFindingDifficultyId: null,
      FunctionalSpecialistStaffingMethodId: null,
      FunctionalSpecialistDisagreementReasonId: null,
      FunctionalSpecialistQualificationExpectation: '',
      VocationalSpecialistCount: 0,
      VocationalSpecialistCycleRate: 0,
      VocationalSpecialistFindingDifficultyId: null,
      VocationalSpecialistStaffingMethodId: null,
      VocationalSpecialistDisagreementReasonId: null,
      VocationalSpecialistQualificationExpectation: '',
      SupportStaffCount: 0,
      SupportStaffCycleRate: 0,
      SupportStaffFindingDifficultyId: null,
      SupportStaffStaffingMethodId: null,
      SupportStaffDisagreementReasonId: null,
      SupportStaffQualificationExpectation: '',
      acceptTrainee: '',
      vocationalTraineeBranch: '',
      vocationalTraineeCount: 0,
      acceptVocationalTrainee: false,
      associateTraineeBranch: '',
      associateTraineeCount: 0,
      acceptAssociateTrainee: false,
      bachelorTraineeBranch: '',
      bachelorTraineeCount: 0,
      acceptBachelorTrainee: false,
      acceptLongTermTrainee: 0,
      iskurApps: 0,
      joinLessons: 0,
      rowState: 1,
    },
    firmEmployementPolicy: {},
    defaultFirmEmployementPolicy: {
      firmEmployementPolicyId: 0,
      firmInfoId: 0,
      q1Value: 0,
      q1Text: '',
      q2Value: 0,
      q2Text: '',
      q3Value: 0,
      q3Text: '',
      q4Value: 0,
      q4Text: '',
      q5Value: 0,
      q5Text: '',
      q6Value: 0,
      q6Text: '',
      q7Value: 0,
      q7Text: '',
      q8Value: 0,
      q8Text: '',
      q9Value: 0,
      q9Text: '',
      q9Description: '',
      q10Value: 0,
      q10Text: '',
      q11Text: '',
      firmStaffingMethods: [],
      firmStaffingMethodsDescription: '',
      firmContactType: 0,
      firmStaffingOption: 0,
      firmStaffingPosition1: '',
      factorFirmStaffingPosition1: null,
      firmStaffingPosition2: '',
      factorFirmStaffingPosition2: null,
      firmStaffingPosition3: '',
      factorFirmStaffingPosition3: null,
      firmYearlyStaffIncrementNumber: 0,
      firmYearlyStaffIncrementPercent: 0,
      firmFutureStaffingPosition1: '',
      factorFirmFutureStaffingPosition1: null,
      firmFutureStaffingPosition2: '',
      factorFirmFutureStaffingPosition2: null,
      firmFutureStaffingPosition3: '',
      factorFirmFutureStaffingPosition3: null,
      firmStaffEducation1: '',
      factorFirmStaffEducation1: null,
      firmStaffEducation2: '',
      factorFirmStaffEducation2: null,
      firmStaffEducation3: '',
      factorFirmStaffEducation3: null,
      rowState: 1,
    },
    firmLogoB64: '',
  },
  getters: {
    GET_FIRMINFO: state => state.firmInfo,
    GET_FIRMBENEFIT: state => state.firmBenefits,
    GET_FIRMCONTACTINFO: state => state.firmContactInfo,
    GET_FIRMEMPLOYEMENTINFO: state => state.firmEmployementInfo,
    GET_EMPLOYEMENTPOLICY: state => state.firmEmployementPolicy,
    GET_LOGO: state => state.firmLogoB64,
  },
  mutations: {
    SET_FIRMINFO: (state, firmInfo) => {
      state.firmInfo = firmInfo
    },
    SET_FIRMBENEFIT: (state, firmBenefits) => {
      state.firmBenefits = firmBenefits
    },
    SET_FIRMCONTACTINFO: (state, firmContactInfo) => {
      state.firmContactInfo = firmContactInfo
    },
    SET_FIRMEMPLOYEMENTINFO: (state, firmEmployementInfo) => {
      state.firmEmployementInfo = firmEmployementInfo
    },
    SET_EMPLOYEMENTPOLICY: (state, firmEmployementPolicy) => {
      state.firmEmployementPolicy = firmEmployementPolicy
    },
    RESET_FIRMINFO: state => {
      state.firmInfo = { ...state.defaultFirmInfo }
    },
    RESET_FIRMBENEFIT: state => {
      state.firmBenefits = { ...state.defaultFirmBenefit }
    },
    RESET_FIRMCONTACTINFO: state => {
      state.firmContactInfo = { ...state.defaultFirmContactInfo }
    },
    RESET_FIRMEMPLOYEMENTINFO: state => {
      state.firmEmployementInfo = { ...state.defaultFirmEmployementInfo }
    },
    RESET_EMPLOYEMENTPOLICY: state => {
      state.firmEmployementPolicy = { ...state.defaultFirmEmployementPolicy }
    },
    SET_LOGO: (state, logoStr) => {
      state.firmLogoB64 = logoStr
    },
  },
  actions: {
    action_get_firminfo({ commit, state }, payload) {
      commit('RESET_FIRMINFO')
      return new Promise((resolve, reject) => {
        if (payload === -1) {
          // commit('SET_FIRMINFO', state.defaultFirmInfo)
          commit('RESET_FIRMINFO')
          resolve(true)
          return
        }
        firmInfoApi.GetFirm(payload).then(resp => {
          if (resp.data.entity == null) {
            commit('SET_FIRMINFO', state.defaultFirmInfo)
            // state.firmInfo.firmInfoId = payload
          } else {
            commit('SET_FIRMINFO', resp.data.entity)
          }
          resolve(true)
        }).catch(error => {
          commit('SET_FIRMINFO', {})
          reject(error)
        })
      })
    },
    action_save_firminfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        let result = -1
        const d = new Date()
        if (state.firmInfo.registerDate === '') {
          state.firmInfo.registerDate = d.toISOString()
        }
        if (state.firmInfo.firmInfoId === 0) {
          firmInfoApi.AddFirm(state.firmInfo).then(resp => {
            result = resp.data.entity
            resolve(result)
            commit('SET_FIRMINFO', state.defaultFirmInfo)
          })
          return result
        }
        if (state.firmInfo.firmInfoId > 0) {
          firmInfoApi.UpdateFirm(state.firmInfo).then(resp => {
            result = resp.data.entity
            resolve(result)
          })
          return result
        }
        reject()
        return result
      })
    },
    action_get_firmbenefit({ commit, state }, payload) {
      commit('RESET_FIRMBENEFIT')
      return new Promise((resolve, reject) => {
        if (payload === -1) {
          commit('RESET_FIRMBENEFIT')
          // commit('SET_FIRMBENEFIT', state.defaultFirmBenefit)
          resolve(true)
          return
        }
        firmBenefitApi.GetBenefitByFirmId(payload).then(resp => {
          if (resp.data.entity == null) {
            commit('SET_FIRMBENEFIT', state.defaultFirmBenefit)
            // state.firmBenefits.firmInfoId = payload
          } else {
            commit('SET_FIRMBENEFIT', resp.data.entity)
          }
          resolve(true)
        }).catch(error => {
          commit('SET_FIRMBENEFIT', {})
          reject(error)
        })
      })
    },
    action_save_firmbenefit({ state }, firmId) {
      return new Promise((resolve, reject) => {
        let result = -1
        firmBenefitApi.GetBenefitByFirmId(firmId).then(res => {
          if (res.data.entity == null) state.firmBenefits.firmBenefitId = 0
          else state.firmBenefits.firmBenefitId = res.data.entity.firmBenefitId

          if (state.firmBenefits.firmBenefitId === 0 && firmId > 0) {
            state.firmBenefits.firmInfoId = firmId
            firmBenefitApi.AddBenefit(state.firmBenefits).then(resp => {
              result = resp.data.entity
              resolve(result)
            })
            return result
          }
          if (state.firmBenefits.firmInfoId > 0) {
            firmBenefitApi.UpdateBenefit(state.firmBenefits).then(resp => {
              result = resp.data.entity ? -1 : 0
              resolve(result)
            })
            return result
          }
          reject()
          return result
        })
      })
    },
    action_get_firmcontactinfo({ commit, state }, payload) {
      commit('RESET_FIRMCONTACTINFO')
      return new Promise((resolve, reject) => {
        if (payload === -1) {
          // commit('SET_FIRMCONTACTINFO', state.defaultFirmContactInfo)
          commit('RESET_FIRMCONTACTINFO')
          resolve(true)
          return
        }
        firmContactInfoApi.GetFirmContactInfoByFirmId(payload).then(resp => {
          if (resp.data.entity == null) {
            commit('SET_FIRMCONTACTINFO', state.defaultFirmContactInfo)
            state.firmContactInfo.firmInfoId = 0
          } else {
            commit('SET_FIRMCONTACTINFO', resp.data.entity)
          }
          resolve(true)
        }).catch(error => {
          commit('SET_FIRMCONTACTINFO', {})
          reject(error)
        })
      })
    },
    action_save_firmcontactinfo({ state }, firmId) {
      return new Promise((resolve, reject) => {
        let result = -1
        firmContactInfoApi.GetFirmContactInfoByFirmId(firmId).then(res => {
          if (res.data.entity == null) state.firmContactInfo.firmContactInfoId = 0
          else state.firmContactInfo.firmContactInfoId = res.data.entity.firmContactInfoId

          if (state.firmContactInfo.firmInfoId === 0 && firmId > 0) {
            state.firmContactInfo.firmInfoId = firmId
            firmContactInfoApi.AddFirmContactInfo(state.firmContactInfo).then(resp => {
              result = resp.data.entity
              resolve(result)
            })
            return result
          }
          if (state.firmContactInfo.firmInfoId === firmId) {
            firmContactInfoApi.UpdateFirmContactInfo(state.firmContactInfo).then(resp => {
              result = resp.data.entity ? -1 : 0
              resolve(result)
            })
            return result
          }
          reject()
          return result
        })
      })
    },
    action_get_firmEmployementInfo({ commit, state }, payload) {
      commit('RESET_FIRMEMPLOYEMENTINFO')
      return new Promise((resolve, reject) => {
        if (payload === -1) {
          // commit('SET_FIRMEMPLOYEMENTINFO', state.defaultFirmEmployementInfo)
          commit('RESET_FIRMEMPLOYEMENTINFO')
          resolve(true)
          return
        }
        firmEmpInfApi.GetEmployementInfoByFirmId(payload).then(resp => {
          if (resp.data.entity == null) {
            commit('RESET_FIRMEMPLOYEMENTINFO')
            commit('SET_FIRMEMPLOYEMENTINFO', state.defaultFirmEmployementInfo)
            // state.firmEmployementInfo.firmInfoId = payload
          } else {
            commit('SET_FIRMEMPLOYEMENTINFO', resp.data.entity)
          }
          resolve(true)
        }).catch(error => {
          commit('SET_FIRMEMPLOYEMENTINFO', {})
          reject(error)
        })
      })
    },
    action_save_firmEmployementInfo({ state }, firmId) {
      return new Promise((resolve, reject) => {
        firmEmpInfApi.GetEmployementInfoByFirmId(firmId).then(res => {
          if (res.data.entity == null) state.firmEmployementInfo.firmEmployementInfoId = 0
          else state.firmEmployementInfo.firmEmployementInfoId = res.data.entity.firmEmployementInfoId

          if (state.firmEmployementInfo.FirmInfoId === 0 && firmId > 0) {
            state.firmEmployementInfo.FirmInfoId = firmId
            firmEmpInfApi.AddEmployementInfo(state.firmEmployementInfo).then()
            resolve(true)
            return
          }
          if (state.firmEmployementInfo.firmInfoId > 0) {
            firmEmpInfApi.UpdateEmployementInfo(state.firmEmployementInfo).then()
            resolve(true)
            return
          }
          reject()
        })
      })
    },
    action_get_firmEmployementPolicy({ commit, state }, payload) {
      commit('RESET_EMPLOYEMENTPOLICY')
      return new Promise((resolve, reject) => {
        if (payload === -1) {
          commit('RESET_EMPLOYEMENTPOLICY')
          // commit('SET_EMPLOYEMENTPOLICY', state.defaultFirmEmployementPolicy)
          resolve(true)
        }
        firmEmpPolApi.GetEmployementPolicyByFirmId(payload).then(resp => {
          if (resp.data.entity == null) {
            commit('SET_EMPLOYEMENTPOLICY', state.defaultFirmEmployementPolicy)
            // state.firmEmployementPolicy.firmInfoId = payload
          } else {
            commit('SET_EMPLOYEMENTPOLICY', resp.data.entity)
          }
          resolve(true)
        }).catch(error => {
          commit('RESET_EMPLOYEMENTPOLICY')
          reject(error)
        })
      })
    },
    action_save_firmEmployementPolicy({ state }, firmId) {
      return new Promise((resolve, reject) => {
        firmEmpPolApi.GetEmployementPolicyByFirmId(firmId).then(res => {
          if (res.data.entity == null) state.firmEmployementPolicy.firmEmployementPolicyId = 0
          else state.firmEmployementPolicy.firmEmployementPolicyId = res.data.entity.firmEmployementPolicyId
          if (state.firmEmployementPolicy.firmEmployementPolicyId === 0 && firmId > 0) {
            state.firmEmployementPolicy.firmInfoId = firmId
            firmEmpPolApi.AddEmployementPolicy(state.firmEmployementPolicy).then()
            resolve(true)
            return
          }
          if (state.firmEmployementPolicy.firmInfoId > 0) {
            firmEmpPolApi.UpdateEmployementPolicy(state.firmEmployementPolicy).then()
            resolve(true)
            return
          }
          reject()
        })
      })
    },
    action_reset_firmInventory({ commit }) {
      commit('RESET_FIRMINFO')
      commit('RESET_FIRMBENEFIT')
      commit('RESET_FIRMCONTACTINFO')
      commit('RESET_FIRMEMPLOYEMENTINFO')
      commit('RESET_EMPLOYEMENTPOLICY')
    },
    action_set_firm_logo({ state }, Logo) {
      return new Promise(resolve => {
        state.firmLogoB64 = Logo
        resolve(Logo)
      })
    },
  },
}
